import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerThighFull = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 204"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M12.28 69.423c-3.23-1.376-1.484-11.95 2.59-14.392 8.006-4.796 11.517 1.938 14.619 4.06l.349.368c2.636.277 5.517.433 7.998 1.504 5.287 2.277 3.59 7.688 1.578 12.022-2.678 5.766-6.34 11.018-8.118 17.225-1.662 5.797-1.543 11.967-1.638 17.96-.103 6.6-.302 13.208-.14 19.805.152 6 .633 11.989.779 17.989.156 6.53-.233 13.315 1.148 19.728 1.263 5.853 4.31 13.683 8.807 13.432 5.082-.285 10.617 6.759 9.818 12.396-1.785 12.58-8.745 16.268-16.752 8.519-1.066-1.032-5.52 4.015-6.67 3.623-4.051-1.374-9.375-3.771-10.243-8.757-.81-4.637 1.75-9.823 2.497-14.33 2.162-13.02-.827-23.26-1.062-39.035-.093-6.221.403-14.976.86-21.198.505-6.865.657-12.313.697-19.19.035-5.988 2.097-10.818 1.23-16.764-.834-5.751-2.273-12.385-8.346-14.965" />
      <Path d="M7.31 14.395c4.304-3.796 6.722-9.234 11.234-12.733 4.762-3.696 10.869-.456 15.185 2.262 4.604 2.9 8.056 6.355 10.64 10.814l1.475 4.213c.14 2.59-1.273 5.228-2.39 7.538-2.045 4.238-5.514 7.654-6.764 12.158-.311 1.127-6.471.427-7.5 1.3-2.87 2.438-3.611 4.556-6.196 6.19-1.67 1.056-2.347 2.856-3.026 5.307-2.306-.46-4.53.345-7.431 1.9-3.422 1.833-3.291 13.302-4.035 12.252-3.381-4.768-1.89-9.15 1.197-13.606 2.899-4.19 6.288-9.222 2.817-13.983C9.286 33.575 2.433 31.694.529 26.385c-1.844-5.146 3.148-8.785 6.78-11.99" />
      <Path d="M39.567 85.099c.725-2.056 1.447-9.825 1.545-14.347.023-.972 2.628-8.811-.868-10.317-2.482-1.07-9.87-2.653-9.87-2.653-1.655-1.131-6.632-4.858-6.416-5.481.705-2.035 2.167-3.832 3.578-4.722 2.374-1.503 1.742-3.654 4.377-5.89.943-.803 3.337-.34 4.953 0 1.616.339 4.202.487 6.304 0 2.1-.487 3.603 2.586 3.002 4.988-.601 2.399.75 2.854 2.4 3.45 1.65.602 1.952 3.153 1.35 5.105-.6 1.95-.15 4.5 1.053 5.104 1.199.596.9 2.25.9 4.597 0 2.345.9 7.559 0 10.41s-4.204 9.305-6.753 11.706c-2.554 2.4-6.455.598-5.555-1.95" />
    </G>
  </Svg>
);
export default SvgLowerThighFull;
