import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgForearmUlnaDistal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 85"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M19.491 68.304c-.91 5.671-1.753 6.872-1.688 9.451.042 1.61.32 6.742-1.797 6.067s-4.97-1.906-3.306-7.122c.72-2.256 1.346-5.51 1.863-8.865.147-.955 5.073-.432 4.928.47" />
      <Path d="M11 67h16v18H11z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgForearmUlnaDistal;
