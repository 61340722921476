import { useMemo } from 'react'
import { useColorScheme, StyleSheet, View } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { Pressable, Text, Icon, Image } from 'components'
import { Theme } from 'styles'

const Card = observer(
  ({
    title = null,
    subtitle = null,
    description = null,
    image = null,
    icon = null,
    onPress = null,
    style = null,
    iconStyle = null,
    titleStyle = null,
    index = 0,
    children = null,
    ...props
  }) => {
    const { colors } = useTheme()
    const scheme = useColorScheme()

    const { rootStore } = useStores()
    const { appStore } = rootStore

    const styles = StyleSheet.create({
      wrap: {
        flex: 1,
        backgroundColor: colors.card,
        marginTop: Theme.spacing.layout,
        borderRadius: Theme.spacing.border,
      },
      content: {
        padding: Theme.spacing.layout,
        flex: 1,
      },
      first: {
        marginTop: 0,
      },
      image: {
        width: '100%',
        height: undefined,
        marginBottom: Theme.spacing.layout,
      },
      title: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
      },
      titleText: {
        flex: 1,
      },
      icon: {},
      titleIcon: {
        marginRight: Theme.spacing.border,
      },
      pressableIcon: {},
      description: {},
    })

    const renderImage = () => {
      let hasImage = image && image.default
      if (!hasImage) return

      const path = useMemo(() => {
        if (scheme === 'dark') {
          return image.dark.url
        } else {
          return image.default.url
        }
      }, [scheme])

      const aspectRatio = useMemo(() => {
        return image.default.width / image.default.height
      })

      return (
        <Image
          source={{ uri: path }}
          style={[styles.image, { aspectRatio: aspectRatio }]}
          contentFit={'contain'}
          alt={title}
        />
      )
    }

    const ConditionalPressable = (props) => {
      const viewProps = {
        style: [styles.wrap, index === 0 && !appStore.isLargeScreen && styles.first, style],
      }

      if (onPress) {
        return (
          <Pressable
            onPress={onPress}
            animated={onPress}
            disabled={!onPress}
            {...viewProps}
          >
            {props.children}
          </Pressable>
        )
      } else {
        return <View {...viewProps}>{props.children}</View>
      }
    }

    return (
      <ConditionalPressable>
        <View style={styles.content}>
          {renderImage()}
          <View style={styles.title}>
            {icon && (
              <Icon
                style={[styles.icon, styles.titleIcon, iconStyle]}
                name={icon}
                size={'h2'}
              />
            )}
            <View style={{ flex: 1 }}>
              <Text
                size={'h2'}
                weight={'bold'}
                style={[styles.titleText, titleStyle]}
              >
                {title}
              </Text>
              {subtitle && <Text>{subtitle}</Text>}
            </View>
            {onPress && (
              <Icon
                style={[styles.icon, styles.pressableIcon]}
                name={'chevron-right'}
                size={'h1'}
              />
            )}
          </View>
          {description && <Text>{description}</Text>}
          {children}
        </View>
      </ConditionalPressable>
    )
  }
)

export default Card
