import { StyleSheet, Text as RNText } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { Theme } from 'styles'

const Text = ({
  size = 'p',
  weight = 'regular',
  style = styles.text,
  children = null,
  color = 'text',
  themed = true,
  ...props
}) => {
  const { colors } = useTheme()

  return (
    <RNText
      style={[
        {
          fontFamily: Theme.font.family[weight],
          fontSize: Theme.font.size[size],
          color: themed ? colors[color] : color,
        },
        style,
      ]}
      {...props}
    >
      {children}
    </RNText>
  )
}

const styles = StyleSheet.create({
  text: {},
})

export default Text
