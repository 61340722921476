import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { About, Settings, Browser } from 'screens'
import { Header, TopTabBar } from 'components/navigation'

const TopTab = createMaterialTopTabNavigator()
const Stack = createNativeStackNavigator()

const AboutTabs = () => {
  return (
    <TopTab.Navigator
      tabBar={(props) => <TopTabBar {...props} />}
    >
      <TopTab.Screen
        name={'about'}
        component={About}
        options={{
          title: 'About',
        }}
      />
      <TopTab.Screen
        name={'settings'}
        component={Settings}
        options={{
          title: 'Settings',
        }}
      />
    </TopTab.Navigator>
  )
}

const AboutStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: (props) => <Header {...props} />
      }}
    >
      <Stack.Screen
        name={'aboutTabs'}
        component={AboutTabs}
        options={{
          title: 'About',
          hideHeader: true,
        }}
      />
      <Stack.Screen
        name={'browser'}
        component={Browser}
        options={{
          title: 'Browser',
        }}
      />
    </Stack.Navigator>
  )
}

export default AboutStack