import { StyleSheet, ScrollView, View, Switch } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { Text, Button, Card } from 'components'
import { Picker } from '@react-native-picker/picker'
import { routeMap } from 'screens'
import { Theme } from 'styles'

const Debug = observer(() => {
  const { rootStore } = useStores()
  const { contentStore, appStore } = rootStore

  const { colors } = useTheme()

  const styles = StyleSheet.create({
    wrap: {
      paddingHorizontal: Theme.spacing.layout,
    },
    card: {
      flex: 0,
      marginBottom: Theme.spacing.layout,
    },
    content: {
      paddingVertical: Theme.spacing.thin,
    }
  })

  const handleRefresh = () => {
    contentStore.clearCache()
    contentStore.loadContent(true)
  }

  const handleToggle = (value) => {
    appStore[value] = !appStore[value]
  }

  const handleReload = () => {}

  return (
    <ScrollView contentContainerStyle={styles.wrap}>
      <Card
        title={'Refresh Content'}
        style={styles.card}
      >
        <View style={styles.content}>
          <Text>Fracture information is automatically refreshed periodically. To force a refresh, press the button below.</Text>
          <Text style={{color: colors.notification}} weight={'bold'}>Note: This will delete all currently stored data.</Text>
        </View>
        <Button
          text={'Refresh'}
          onPress={handleRefresh}
          disabled={contentStore.refreshing}
        />
      </Card>
      <Card
        title={'Debug Mode'}
        style={styles.card}
      >
        <Switch
          style={{marginTop: Theme.spacing.thin}}
          trackColor={{false: colors.background, true: colors.text}}
          onValueChange={() => handleToggle('debug')}
          value={appStore.debug}
        />
      </Card>
      <Card
        title={'First Launch'}
        style={styles.card}
      >
        <Switch
          style={{marginTop: Theme.spacing.thin}}
          trackColor={{false: colors.background, true: colors.text}}
          onValueChange={() => handleToggle('firstLaunch')}
          value={appStore.firstLaunch}
        />
      </Card>
      <Card
        title={'Initial Screen'}
        description={'Set the initial screen on launch'}
        style={styles.card}
      >
        <Picker
          selectedValue={routeMap[appStore.initialRoute].name}
          onValueChange={(value, index) => {appStore.setInitialRoute(index)}}
        >
          {routeMap.map(route => <Picker.Item label={route.title} value={route.name} key={route.name} />)}
        </Picker>
      </Card>
    </ScrollView>
  )
})

export default Debug