import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerLegTibiaShaft = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 136"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M7.598 36.155c1.933 11.737.97 25.442.766 38.79-.153 10.034-.794 19.56-1.163 28.653-.15 3.699-.4 7.124-.837 10.372-.139 1.032 8.561 1.462 8.583.548.122-5.045.51-10.052.253-15.14-.355-7.039.437-14.159.437-21.96 0-11.603.646-22.172 1.164-33.017.13-2.728.193-5.529.335-8.267.053-1-9.736-1.18-9.538.021" />
      <Path
        d="M0 115h16q-.606-30.205 0-45.342.606-15.135 3.206-34.658H0z"
        onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0}
      />
    </G>
  </Svg>
);
export default SvgLowerLegTibiaShaft;
