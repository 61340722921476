import { StyleSheet, View } from 'react-native'
import { Pressable, Text, Icon } from 'components'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTheme } from '@react-navigation/native'
import { Theme } from 'styles'

const BottomTabBar = ({ state, descriptors, navigation }) => {
	const { colors } = useTheme()
  const { bottom } = useSafeAreaInsets()

  const icons = {
    extremityStack: 'human',
    listStack: 'sort-alphabetical-ascending',
    aboutStack: 'information-outline',
    debugStack: 'bug',
  }

  const styles = StyleSheet.create({
    wrap: {
      flexDirection: 'row',
      paddingTop: Theme.spacing.border,
      paddingBottom: bottom + Theme.spacing.border,
      paddingHorizontal: Theme.spacing.thin,
      backgroundColor: colors.background,
    },
    tab: {
      flex: 1,
      alignItems: 'center',
      opacity: 0.4,
    },
    tabActive: {
      opacity: 1,
    },
    text: {
      fontSize: Theme.font.size.sm,
      textTransform: 'uppercase',
      letterSpacing: 2,
    },
    icon: {
      color: colors.text,
    },
  })

  return (
    <View style={styles.wrap}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key]
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name

        const focused = state.index === index

        const handlePress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          })

          if (!focused && !event.defaultPrevented) {
            navigation.navigate({ name: route.name, merge: true })
          }
        }

        return (
          <Pressable
            key={route.key}
            onPress={handlePress}
            animated={false}
            style={[
              styles.tab,
              focused && styles.tabActive,
            ]}
          >
            <Icon
              name={icons[route.name]}
              style={styles.icon}
              size={'h2'}
            />
            <Text style={styles.text}>
              {label}
            </Text>
          </Pressable>
        )
      })}
    </View>
  )
}

export default BottomTabBar