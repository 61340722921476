import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { Pressable, Text, Icon } from 'components'
import { Theme } from 'styles'

const Item = ({
  title = null,
  onPress = () => null
}) => {
  const { colors } = useTheme()

  const styles = StyleSheet.create({
    item: {
      paddingHorizontal: Theme.spacing.layout,
      paddingVertical: Theme.spacing.thin,
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderBottomColor: colors.border,
    },
    itemText: {
      flex: 1,
    },
  })

  return (
    <Pressable
      onPress={onPress}
      style={styles.item}
    >
      <Text style={styles.itemText}>{title}</Text>
      <Icon name={'chevron-right'} size={'h3'} color={'border'} />
    </Pressable>
  )
}

export default React.memo(Item)