import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerLegPatella = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 136"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M14.329.034c-2.081-.218-2.631.658-4.074 1.312-1.461 2.342-2.863 6.081-1.891 9.455.535 1.852 3.156 4.461 4.656 4.799.841.188 1.511-.141 2.325 0C21.273 13.037 22.353.89 14.329.034" />
  </Svg>
);
export default SvgLowerLegPatella;
