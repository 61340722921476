import { useMemo, useState } from 'react'
import { StyleSheet, ScrollView, View } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { StatusBar } from 'expo-status-bar'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Text, Pressable, Image } from 'components'
import { HTMLRenderer } from 'components/HTMLRenderer'
import ImageView from 'react-native-image-viewing'
import { Theme } from 'styles'

const Gallery = ({
  gallery = []
}) => {

	const { colors } = useTheme()
  const { bottom } = useSafeAreaInsets()

  const [ galleryVisible, setGalleryVisible ] = useState(false)
  const [ galleryIndex, setGalleryIndex ] = useState(0)

  const galleryUris = useMemo(() => {
    if (gallery) {
      return gallery.map(item => {
        return { uri: item.url }
      })
    }
  })

  const styles = StyleSheet.create({
    wrap: {
      marginTop: Theme.spacing.layout,
    },
    gallery: {
      paddingVertical: Theme.spacing.layout,
    },
    image: {
      marginRight: Theme.spacing.thin,
    },
    caption: {
      flex: 1,
      padding: Theme.spacing.layout,
      paddingBottom: Theme.spacing.layout + bottom,
      backgroundColor: Theme.color.light.colors.text + Theme.opacity.opaque,
    },
    captionText: {
      marginBottom: Theme.spacing.border,
    },
    licenseText: {
      color: Theme.color.light.colors.background + Theme.opacity.opaque,
      fontSize: Theme.font.size.sm,
    }
  })


  const statusBarStyle = useMemo(() => {
    return galleryVisible ? 'light' : 'auto'
  }, [galleryVisible])
  
  return (
    <View style={styles.wrap}>
      <StatusBar style={statusBarStyle} animated={true} />
      <Text size={'h3'} weight={'bold'}>Gallery</Text>
      <ScrollView
        contentContainerStyle={styles.gallery}
        horizontal={true}
      >
        {gallery.map((image, index) => {
          return (
            <Pressable
              key={image.name}
              style={styles.image}
              onPress={() => {
                setGalleryIndex(index)
                setGalleryVisible(true)
              }}
            >
              <Image
                source={{uri: image.sizes.thumbnail}}
                style={{
                  width: image.sizes['thumbnail-width'],
                  height: image.sizes['thumbnail-height'],
                  borderRadius: Theme.spacing.border,
                }}
              />
            </Pressable>
          )
        })}
      </ScrollView>
      <ImageView
        images={galleryUris}
        imageIndex={galleryIndex}
        visible={galleryVisible}
        onRequestClose={() => setGalleryVisible(false)}
        FooterComponent={({ imageIndex }) => {
          let item = gallery[imageIndex]
          return (
            <View style={styles.caption}>
              <Text allowFontScaling={false} color={'white'} style={styles.captionText} themed={false}>{item.caption}</Text>
              <HTMLRenderer
                html={item.description}
                baseStyles={styles.licenseText}
                linkPress={() => setGalleryVisible(false)}
                defaultTextProps={{ allowFontScaling: false }}
              />
            </View>
          )
        }}
      />
    </View>
  )
}


export default Gallery