import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootCuboid = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M32.019 107.946c1.426.876 3.339.724 4.874 1.63 1.547.912 2.785 2.073 4.62 1.965 1.75-.105 2.756-.762 4.17-1.836 1.208-.92 3.245-.93 3.82-2.53.237-.661.428-2.101.73-2.728 1.615-3.361 2.246-6.344.624-9.664-2.731-5.588-9.06-9.693-11.492-1.646-.945 3.132-1.495 5.308-3.975 7.315-2.614 2.115-4.002 3.68-3.371 7.494" />
  </Svg>
);
export default SvgLowerFootCuboid;
