import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandScaphoid = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M43.946 129.345c1.895-1.375 5.218-3.324 5.029-5.106-.24-2.265-4.182-4.892-6.324-3.196-.431.344-.477 1.074-.35 1.651-2.87-.89-2.71 1.872-4.786 2.904-2.2 1.1-4.428-.019-4.465 3.35-.06 5.478 8.382 2.23 10.896.397" />
  </Svg>
);
export default SvgHandScaphoid;
