import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandLunate = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M22.325 121.378c2.022 3.244-2.24 7.72 1.774 10.183 1.477.9 3.99 1.455 5.585.575 1.758-.972 1.73-3.163 1.576-4.902-.023-.237-.006-.734-.122-.94-1.861-3.296-3.814-7.846-4.098-11.447-.08-1.056.16-5.303-1.5-5.483-1.86-.202-1.128 3.845-1.242 4.804-.286 2.392-2.111 1.712-3.465 3.14-1.48 1.553.669 2.757 1.492 4.07" />
  </Svg>
);
export default SvgHandLunate;
