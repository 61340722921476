import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerLegTibiaProximal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 136"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M21.164 9.2c-1.306 3.058-2.73 5.998-5.381 7.709-6.353 1.364-6.388-4.94-9.456-6.837C4.306 8.823.024 9.228-.362 11.528-.629 13.111.82 15.3 1.383 16.473c2.296 4.771 4.145 10.045 5.527 16.144.262 1.157.189 2.457.688 3.538 1.965 4.257 9.396 2.717 9.538-.021.053-1 .115-1.991.196-2.967.318-3.847.915-7.461 2.231-10.441 1.335-3.013 4.184-4.711 5.674-7.416.888-1.615 2.257-5.427-.437-6.11-1.138-.292-2.478.215-3.636 0" />
      <Path
        d="M0 39h19q-.8-11.184.563-16.274c.578-2.16 3.825-4.33 5.674-7.416Q26.295 13.545 27 9.107L0 9z"
        onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0}
      />
    </G>
  </Svg>
);
export default SvgLowerLegTibiaProximal;
