import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerLegFibulaShaft = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 136"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M19.855 38.582c.031 3.936-.292 8.071-.292 12.218 0 12.324-.939 24.25-1.745 35.489-.11 1.547-.618 3.25-.726 4.655-.411 5.28-.633 10.79-.873 16.289-.074 1.68 3.028 2.215 3.124.82.46-6.697 1.217-13.292 1.531-20.017.385-8.299.953-16.732 1.453-25.017.525-8.695.271-17.263.582-25.601.127-3.382-3.059.578-3.054 1.164" />
      <Path d="M16 36h11v73H16z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgLowerLegFibulaShaft;
