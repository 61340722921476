import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgForearmRadiusProximal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 85"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M8.575 4.076c1.214 1.696 1.328 4.995.504 10.068-.119.727 5.365 1.049 5.484.426.823-4.269-1.192-8.312.237-10.086C16.23 2.714 15.681-.9 13.514.3c-2.229 1.232-2.459.575-3.272.434-3.029-.52-3.418.896-1.667 3.342" />
      <Path d="M0 0h15v15H0z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgForearmRadiusProximal;
