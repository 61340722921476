import { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import { Platform, useColorScheme } from 'react-native'
import * as SplashScreen from 'expo-splash-screen'
import * as NavigationBar from 'expo-navigation-bar'
import { StatusBar } from 'expo-status-bar'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { NavigationContainer } from '@react-navigation/native'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { ExtremityStack, ListStack, AboutStack, Loading, Intro, linking } from 'screens'
import { Header, TopTabBar, BottomTabBar, Sidebar } from 'components/navigation'
import { loadFonts, logScreen, removeLogBox, setTabletOrientation } from 'utils'
import { Theme } from 'styles'


SplashScreen.preventAutoHideAsync()

const App = observer(() => {
  const { rootStore } = useStores()
  const { contentStore, appStore } = rootStore

  const BottomTab = createBottomTabNavigator()
  const Drawer = createDrawerNavigator()

  const [ appIsReady, setAppIsReady ] = useState(false)
  const scheme = useColorScheme()

  const routeNameRef = useRef(null)
  const navigationRef = useRef(null)

  const MainNavigator = useMemo(() => {
    if (appStore.isLargeScreen) {
      return Drawer
    } else {
      return BottomTab
    }
  }, [appStore.isLargeScreen])

  useEffect(() => {
    async function prepare() {
      try {
        await loadFonts()
        await removeLogBox()
        await appStore.setDeviceType()
        await handleAndroidNavigationBar()
        await setTabletOrientation(appStore.deviceType)
      } catch (error) {
        console.warn(error)
      } finally {
        setAppIsReady(true)
        await contentStore.loadContent()
      }
    }

    prepare()
  }, [])

  const handleAndroidNavigationBar = useCallback(async () => {
    if (Platform.OS === 'android') {
      await NavigationBar.setBackgroundColorAsync(theme.colors.background)
      await NavigationBar.setButtonStyleAsync(scheme === 'dark' ? 'light' : 'dark')
    }
  }, [scheme])

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync()
    }
  }, [appIsReady])

  const theme = useMemo(() => Theme.color[scheme], [scheme])

  if (!appIsReady) {
    return null
  }

  return (
    <SafeAreaProvider>
      <StatusBar backgroundColor={theme.colors.background} />
      <NavigationContainer
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef.current.getCurrentRoute()?.name
          onLayoutRootView()
        }}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current
          const currentRouteName = navigationRef.current.getCurrentRoute()?.name

          if (previousRouteName !== currentRouteName) {
            await logScreen(currentRouteName)
          }
          routeNameRef.current = currentRouteName
        }}
        documentTitle={{
          formatter: (options, route) => options?.title ?? 'Fractures'
        }}
        theme={theme}
        linking={linking}
      >
        {contentStore.loading
          ? <Loading /> 
          : appStore.firstLaunch
          ? <Intro />
          : <MainNavigator.Navigator
            tabBar={(props) => <BottomTabBar {...props} />}
            drawerContent={(props) => <Sidebar {...props} />}
            defaultStatus={'open'}
            screenOptions={{
              headerShown: false,
              drawerType: 'permanent',
              drawerStyle: {
                borderRightWidth: 0,
              }
            }}
            initialRouteName={appStore.initialRouteName}
          >
            <MainNavigator.Screen
              name={'extremityStack'}
              component={ExtremityStack}
              options={{title: 'Extremity'}}
            />
            <MainNavigator.Screen
              name={'listStack'}
              component={ListStack}
              options={{title: 'List'}}
            />
            <MainNavigator.Screen
              name={'aboutStack'}
              component={AboutStack}
              options={{title: 'About'}}
            />
          </MainNavigator.Navigator>
        }
      </NavigationContainer>
    </SafeAreaProvider>
  )
})

export default App