import { LogBox } from 'react-native'

const removeLogBox = () => {
  LogBox.ignoreLogs([
		'Require cycle:',
    'Sending `onAnimatedValueUpdate` with no listeners registered.',
  ])
}

export {
  removeLogBox,
}