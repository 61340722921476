import { StyleSheet, View } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import AppIntroSlider from 'react-native-app-intro-slider'
import { Logo, Text } from 'components'
import { Theme } from 'styles'

const Intro = observer(() => {
  const { rootStore } = useStores()
  const { contentStore, appStore } = rootStore

  const { colors } = useTheme()
  const { bottom } = useSafeAreaInsets()

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.background,
    },
    logo: {
      flex: 1,
      justifyContent: 'flex-end',
    },

    slider: {
      flex: 1,
      width: '100%',
      marginBottom: bottom + Theme.spacing.thin,
    },
    content: {
      marginHorizontal: Theme.spacing.layout,
      padding: Theme.spacing.layout,
      backgroundColor: colors.card,
    },

    dotStyle: { backgroundColor: colors.border },
    activeDotStyle: { backgroundColor: colors.text },

    button: {
      paddingVertical: Theme.spacing.thin,
      paddingHorizontal: Theme.spacing.layout,
      borderWidth: 4,
      borderColor: colors.text,
    },
  })

  const data = [
    {
      key: 'welcome',
      title: 'Welcome to Fractures.app',
      text: 'An easy-to-use, free bedside application for fractures and splinting.',
    },
    {
      key: 'how-to-use',
      title: 'How to Use',
      text: 'It’s simple, just select the bone that’s broken and find the fracture that you need help with. You can also search a list of fracture, bones and splints.',
    },
    {
      key: 'get-recommendations',
      title: 'Get Recommendations',
      text: 'Find out what splint to use, and how to apply it. You’ll also get warnings for high-risk complications, who needs an emergent consult and follow-up recommendations.',
    }
  ]

  const renderItem = ({ item }) => {
    return (
      <View style={styles.content}>
        <Text size={'h3'} weight={'bold'}>{item.title}</Text>
        <Text allowFontScaling={false}>{item.text}</Text>
      </View>
    )
  }

  const renderButton = (text) => {
    return (
      <View style={styles.button}><Text weight={'bold'}>{text}</Text></View>
    )
  }

  const handleDone = () => {
    appStore.firstLaunch = false
  }

  return (
    <View style={styles.wrap}>
      <View style={styles.logo}>
        <Logo />
      </View>
      <View style={styles.slider}>
        <AppIntroSlider
          renderItem={renderItem}
          data={data}
          dotStyle={styles.dotStyle}
          activeDotStyle={styles.activeDotStyle}
          renderNextButton={() => renderButton('Next')}
          showPrevButton={true}
          renderPrevButton={() => renderButton('Previous')}
          renderDoneButton={() => renderButton('Done')}
          onDone={handleDone}
          onSkip={handleDone}
        />
      </View>
    </View>
  )
})

export default Intro