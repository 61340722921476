import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerLegFibulaDistal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 136"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M15.938 112.313c-.232 3.902-.436 7.81-.155 11.504.306 4.029 1.914 8.03 1.599 12.215 3.162-1.127 2.219-6.547 1.892-10.47-.378-4.541-.37-8.957-.173-13.31.063-1.406-3.062-1.639-3.163.061" />
      <Path d="M16 111h11v25H16z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgLowerLegFibulaDistal;
