import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgForearmUlnaShaft = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 85"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M23.733 20.293c-.713 8.254-1.489 18.615-1.298 23.2.212 5.107-1.372 15.165-2.422 21.606-.198 1.217-5.165.818-5.036-.132.466-3.432.813-6.7 1.023-8.825.472-4.78-.279-20.342-.678-25.43-.261-3.342-.782-7.723-.789-10.89-.003-1.656 9.299-.681 9.2.471" />
      <Path d="M11 67h16V18H11z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgForearmUlnaShaft;
