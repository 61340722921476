import * as Linking from 'expo-linking'

const linking = {
  prefixes: [Linking.createURL('/'), 'https://fractures.app'],
  config: {
    screens: {
      extremityStack: {
        initialRouteName: 'extremityTabs',
        screens: {
          extremityTabs: {
            initialRouteName: 'extremity',
            screens: {
              upper: 'extremity/upper',
              lower: 'extremity/lower',
            }
          },
          region: 'extremity/:extremity/:region',
          bone: 'extremity/:extremity/:region/:bone/:segment?',
          fracture: 'extremity/:extremity/:region/:bone/:fracture',
          splint: 'extremity/:extremity/:region/:bone/:fracture/:splint/:payload?',
        }
      },
      listStack: {
        initialRouteName: 'listTabs',
        screens: {
          listTabs: {
            initialRouteName: 'list',
            screens: {
              fractures: 'list/fractures',
              bones: 'list/bones',
              splints: 'list/splints',
            }
          },
          bone: 'list/bone/:bone',
          fracture: 'list/fractures/:fracture',
          splint: 'list/fractures/:fracture/:splint/:payload?'
        }
      },
      aboutStack: {
        initialRouteName: 'aboutTabs',
        screens: {
          aboutTabs: {
            initialRouteName: 'about',
            screens: {
              about: 'about/:page?',
              settings: 'settings',
            }
          },
          browser: 'about/browser/:uri',
        },
      },
    }
  }
}

const routeMap = [
  { name: 'extremityStack', title: 'Extremity' },
  { name: 'listStack', title: 'List' },
  { name: 'aboutStack', title: 'About' },
  { name: 'debugStack', title: 'Debug' },
]

export {
  linking,
  routeMap,
}