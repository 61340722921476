import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { Upper, Lower, Region, Bone, Fracture, Splint, Browser } from 'screens'
import { Header, TopTabBar } from 'components/navigation'

const TopTab = createMaterialTopTabNavigator()
const Stack = createNativeStackNavigator()

const ExtremityTabs = () => {
  return (
    <TopTab.Navigator
      tabBar={(props) => <TopTabBar {...props} />}
    >
      <TopTab.Screen
        name={'upper'}
        component={Upper}
        options={{
          title: 'Upper'
        }}
      />
      <TopTab.Screen
        name={'lower'}
        component={Lower}
        options={{
          title: 'Lower'
        }}
      />
    </TopTab.Navigator>
  )
}

const ExtremityStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: (props) => <Header {...props} />,
      }}
    >
      <Stack.Screen
        name={'extremityTabs'}
        component={ExtremityTabs}
        options={{
          title: 'Extremity',
          hideHeader: true,
        }}
      />
      <Stack.Screen
        name={'region'}
        component={Region}
        options={{
          title: 'Region',
        }}
      />
      <Stack.Screen
        name={'bone'}
        component={Bone}
        options={{
          title: 'Bone',
        }}
      />
      <Stack.Screen
        name={'fracture'}
        component={Fracture}
        options={{
          title: 'Fracture',
        }}
      />
      <Stack.Screen
        name={'splint'}
        component={Splint}
        options={{
          title: 'Splint',
        }}
      />
      <Stack.Screen
        name={'browser'}
        component={Browser}
        options={{
          title: 'Browser',
        }}
      />
    </Stack.Navigator>
  )
}

export default ExtremityStack