import * as React from "react";
import Svg, { Circle } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgDebugCirc = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 400 800"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Circle cx={166} cy={512} r={100} />
  </Svg>
);
export default SvgDebugCirc;
