import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgForearmRadiusShaft = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 85"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M8.667 16.435Q8.074 19.46 7.135 23.2c-3.306 13.203-3.4 28.885-3.71 36.974a64 64 0 0 1-.228 3.449c-.08.866 7.064.912 7.011.046-.647-10.695-.898-23.195.121-28.13a2227 2227 0 0 0 3.824-18.857c.153-.78-5.33-1.046-5.486-.246" />
      <Path d="M0 16h16v48H0z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgForearmRadiusShaft;
