import Empty from 'components/Empty'
import Search from 'components/list/Search'
import Item from 'components/list/Item'
import SectionHeader from 'components/list/SectionHeader'

const listViewProps = {
  contentContainerStyle: {flexGrow: 1},
  keyboardDismissMode: 'on-drag',
  keyboardShouldPersistTaps: 'always',
  ListEmptyComponent: <Empty />,
}

export {
  Search,
  Item,
  SectionHeader,
  listViewProps,
}