import { Platform } from 'react-native'
import * as Haptics from 'expo-haptics'

const withHaptic = async (
  style = 'Light'
  ) => {
    if (Platform.OS !== 'web') {
      try {
        await Haptics.impactAsync(Haptics.ImpactFeedbackStyle[style])
      } catch (error) {
        console.log("Haptic unavailable: ", error)
      }
  } else return false
}

export {
  withHaptic,
}