import { StyleSheet, View } from 'react-native'
import { Pressable, Text, Icon } from 'components'
import { throttle } from 'lodash'
import { useTheme } from '@react-navigation/native'
import { Theme } from 'styles'

const Button = ({
  text = 'Button',
  icon = null,
  onPress = () => null,
  style = null,
  textStyle = null,
  disabled = false,
  ...props
}) => {
	const { colors } = useTheme()

  const styles = StyleSheet.create({
    button: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: Theme.spacing.thin,
      paddingHorizontal: Theme.spacing.layout,
      borderWidth: 4,
      borderColor: colors.text,
    },
    buttonDisabled: {
      borderColor: colors.text + Theme.opacity.translucent,
    },
    text: {
      fontSize: Theme.font.size.h4,
      fontFamily: Theme.font.family.bold,
    },
    textDisabled: {
      color: colors.text + Theme.opacity.translucent,
    },
    icon: {
      marginRight: Theme.spacing.border,
    }
  })

	return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      style={[
        styles.button,
        disabled && styles.buttonDisabled,
        style
      ]}
      {...props}
    >
      {icon &&
        <Icon
          name={icon}
          style={[
            styles.icon,
            disabled && styles.textDisabled,
          ]}
        />
      }
      <Text
        style={[
          styles.text,
          disabled && styles.textDisabled,
          textStyle,
        ]}
      >
        {text}
      </Text>
    </Pressable>
	)
}

export default Button