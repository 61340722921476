import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgUpperArmHumerusShaft = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85 129"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M18.278 34.743c-.119 5.834-.85 16.77-1.77 27.504a835 835 0 0 1-.267 3.054c-.938 10.497-2.02 20.308-2.84 24.35-1.003 4.952-1.727 9.579-2.622 13.438-.336 1.446 9.512 1.76 9.637.11.655-8.699 2.007-23.01 3.528-36.449.156-1.381.315-2.754.474-4.11 1.293-10.998 2.666-20.926 3.814-26.023.4-1.772-9.917-3.678-9.954-1.874" />
      <Path d="M6 34h30v70H6z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgUpperArmHumerusShaft;
