import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFoot = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 102 373"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <G>
        <Path d="M58.062 365.472c-.04-1.625-1.835-3.52-2.905-4.59-1.071-1.068-1.377-2.752-3.363-2.292-1.988.46-20.641 7.493-22.782 7.493-2.14 0-2.752.61-1.988 1.68.765 1.073 2.907 2.599 4.283 2.449 1.374-.154 2.598-1.987 4.89-2.907 2.294-.92 7.188-1.833 9.787-1.986 2.598-.155 3.952 1.833 6.866 1.833 2.919 0 5.247-.19 5.212-1.68" />
        <Path d="M28.095 370.006c-.448.985-1.223.836-3.057.216-1.838-.622-4.842-.754-6.372 0-1.53.752-3.11-1.084-2.192-1.997.919-.92.766.149 2.906 0 2.14-.153 3.21-.768 4.434-1.227 1.224-.457 2.549-1.495 2.549-.05 0 1.552 2.075 2.304 1.732 3.058" />
        <Path d="M14.188 368.074c.304-.037.616.079.919.082.317.007.668-.394.91-.188.049.042-.052.188-.052.257.005.44.257 1.142-.096 1.51-.312.33-1.044.13-1.46.175-.592.064-.875-.174-.892-.803-.01-.465.157-.971.67-1.033M9.656 369.222c.479-.121.969.236 1.445.083.384-.125.605-.577.838-.88l.305-.15c1.02-.412.914.982 1.172 1.59.148.352.285.883-.231.998-.388.09-.691-.277-1.057-.33-.466-.065-.723.223-1.064.468-.368.263-.81.234-1.233.113-.45-.123-.74-.39-.772-.872-.028-.43.147-.905.597-1.02" />
        <Path d="M8.333 367.42c.23-.211.768-.053 1.053-.122.275-.065.552-.243.789-.39.469-.298 1.123-.743 1.356.09v.359c-.362.353-.06.59-.1.995-.042.459-.443.462-.78.305-.32-.147-.565-.347-.934-.2-.255.1-.46.46-.761.4-.322-.063-.88-1.205-.623-1.437M6.24 368.506c.344-.1.422-.36.602-.633.204-.317.433-.42.808-.458.03.085.077.163.11.247l.104.253c.22.28.934 1.324.286 1.51-.274.08-.581-.24-.844-.282-.312-.05-.58.13-.865.216-.4.114-1.563.248-1.727-.314-.21-.735 1.194-.45 1.526-.54M12.285 366.617c.75-.442 1.832-.052 2.63.022.89.074 1.738-.136 2.605-.298.809-.147 1.642-.248 2.367-.665.635-.361 1.148-.765 1.893-.895.182-.037.409-.19.557-.073.089.073.015.348.052.456.179.58.815 1.419.22 1.935-.584.51-1.67.279-2.368.248-.91-.047-1.78.088-2.676.199-.841.105-1.691.115-2.52.295-.78.169-1.678.56-2.474.253-.64-.243-.988-1.064-.286-1.477M24.837 363.759c2.222-.944 4.698-1.2 6.974-1.991 2.42-.843 4.712-2.038 7.038-3.107 2.362-1.086 4.665-2.292 7.015-3.401 1.732-.82 3.329-1.191 5-.052.358.242.74.463 1.032.785.14.154.757.855.763 1.07.054 1.746-2.377 1.537-4.026 2.039a66 66 0 0 0-6.456 2.334c-1.966.828-3.877 1.654-5.958 2.163-2.045.503-3.544 1.153-5.526 1.873-1.781.643-4.738.253-7.083.916-.494.14-.843-.858-.752-1.477.099-.683 1.43-.921 1.979-1.152" />
        <Path d="M32.834 359.305c2.17-1.024 4.386-1.673 6.433-2.894 1.668-.996 3.118-2.254 4.864-3.532 1.236-.902 2.616-.764 3.585 1.076.026.049.122.148.102.202-.124.322-.643.25-.964.37-1.466.569-2.923 1.158-4.345 1.827-1.672.789-3.296 1.673-4.98 2.428-1.81.813-3.616 1.828-5.46 2.56-1.82.723-3.862 1.023-5.706 1.684-.855.303-3.293 1.239-4.195 1.34-.433.05-.237-.428-.598-.782-.81-.788 4.23-.966 11.264-4.28M10.94 365.326c1.77-.267 3.52-.004 5.27-.503.794-.23 1.694-.352 2.456-.652.798-.314 1.965-1.174 2.287-.68.134.206.452 1.06.31 1.175-1.425 1.16-3.313 1.605-4.89 1.737-.892.074-1.704-.017-2.581-.125-.852-.106-2.998-.131-3.69.36-.507.36-.652.235-.822-.38-.177-.636 1.303-.88 1.66-.932M5.406 366.99c.123-.28.418-.332.698-.324.322.002.66.086.97-.04.299-.123.476-.402.734-.58.24-.164.556-.216.783 0 .239.232.215.6.188.903l-.077.103c-.285.224-.56.299-.924.268-.334-.025-.668-.096-.941.152-.207.189-.327.486-.595.61-.268.122-.54-.02-.701-.241-.168-.233-.252-.58-.135-.851M2.268 367.905c.293-.027.588.027.87-.086.269-.11.482-.322.7-.504.224-.185.49-.386.8-.344.34.044.444.348.499.646q-.007.024-.026-.007l.15.179c.133.228.306.538-.056.617-.31.067-.656-.044-.963-.08a2.1 2.1 0 0 0-.851.07c-.268.088-.498.298-.774.356-.59.133-1.17-.76-.35-.847" />
        <Path d="M.28 366.079c.302-.23.582-.04.87.098.347.167.887.185 1.249.007.354-.177 1.435-.884 1.435-.433 0 .915.299.959.152 1.22-.132.233-.518.344-.762.4-.33.07-.648.017-.976-.042a7 7 0 0 0-1.105-.115c-.33-.005-.757.007-1.006-.251-.255-.263-.122-.681.143-.884M4.608 365.557c.342.02.7.109 1.044.02.317-.085.505-.334.735-.548.629-.588 1.455-.137 1.477.548.002.067-.091.19-.128.249a1.28 1.28 0 0 1-.732.562c-.374.11-.747.005-1.123.005-.312-.005-.508.136-.747.31-.228.17-.437.228-.671.03-.221-.182-.437-.556-.418-.856.02-.31.308-.331.563-.32" />
        <Path d="M.78 363.613c.555-.275 1.353-.022 1.94.016.666.043 1.369.022 2.01-.18.8-.244 1.375-.818 2.055-1.273.258-.17.558-.33.878-.26.304.065.545.316.76.523 0 0 .2.356.225.357.067.178.14.348.195.532.078.249.164.578-.01.81-.165.22-.491.265-.742.3-.666.085-1.247.153-1.851.464-.597.312-1.14.489-1.82.489-.316 0-.631-.013-.945.039-.305.047-.585.169-.872.278-.678.263-1.357.247-1.84-.37-.357-.447-.65-1.398.018-1.725M9.707 364.186c.942-.184 1.898-.311 2.846-.46 1.01-.162 2.052-.177 3.05-.42a6.4 6.4 0 0 0 1.556-.616c.457-.251.885-.56 1.359-.78.364-.162.753-.24 1.125-.058.408.19.802.516.988.918.03.072.08.236.048.306-.08.169-.328.19-.495.284-.705.384-1.452.598-2.22.816-.832.23-1.643.568-2.493.735-.895.17-1.757.012-2.655-.02-.926-.036-1.727.296-2.6.554-.477.144-1.627.427-1.868-.258-.235-.671.947-.922 1.359-1.001" />
        <Path d="M8.402 361.443c.248-.243.873-.194 1.187-.211 1.187-.07 2.375-.142 3.564-.196 1.167-.052 2.364-.11 3.467-.531.84-.32 1.584-.931 2.522-.931.425 0 .87.12 1.172.436.296.315.396.743.44 1.153.013.096-.174.42-.3.438-.798.094-1.617.084-2.4.295-.878.235-1.606.826-2.465 1.12-1.02.359-2.058.27-3.114.386-.51.055-1 .182-1.487.342-.358.116-.78.315-1.165.268-.342-.037-.526-.334-.678-.61-.216-.386-.413-.791-.61-1.19-.101-.2-.337-.565-.133-.77M21.31 361.384c.442-.226 1.308-.147 1.818-.238 1.208-.22 2.227-.44 3.343-.975 2-.952 4.048-1.883 6.099-2.726 2.154-.885 3.963-2.327 6.02-3.433.893-.475 1.917-1.097 2.436-2.003.56-.981 1.253-1.568 2.199-2.197.94-.622 1.836-1.067 2.992-1.09 1.18-.026 1.668.632 2.136 1.613.049.105-.071.35-.021.455.499 1.012-2.165.373-3.041.872-.948.538-1.867.927-2.71 1.654-5.848 5.038-18.213 8.779-18.696 8.86-.488.078-.988.147-1.467.263-.37.084-.84.51-1.191.42-.278-.457-.556-1.153.083-1.475" />
        <Path d="M22.06 358.362c1.009-.302 2.023.204 3.039.004 1.909-.373 3.684-1.147 5.425-1.97.89-.418 1.83-.701 2.707-1.146.853-.434 1.518-1.025 2.216-1.676 1.258-1.167 2.778-2.162 4.326-2.898.805-.385 1.48-.827 1.969-1.58.274-.415.53-.648 1.09-.601.396.036.886.21.858.692 0 0-1.368 1.096-1.934 1.593-.695.612-1.168 1.423-1.905 1.998-1.414 1.104-7.626 4.667-9.295 5.394-.73.315-3.882 1.499-4.722 1.752-.71.207-3.878 1.175-4.455.49-.586-.695-.526-1.686.68-2.052M53.864 352.997c1.577-.442 3.084-1.121 4.672-1.512 1.5-.371 3.018-.302 4.54-.093.367.052.732.113 1.102.165.152.02.486-.013.61.076 1.294.933 1.164 2.823 1.13 4.436-.03 1.486-.132 3.052-.397 4.519-.496 2.74-3.447 5.058-6.134 3.412-2.49-1.527-3.922-4.243-5.691-6.456-.474-.595-.993-1.153-1.501-1.723-.33-.367-.927-.883-1.017-1.401-.181-1.03 2.036-1.241 2.686-1.423" />
        <Path d="M56.799 343.17c.78-.25 1.8-.567 2.608-.28.884.32.942 1.293 1.046 2.099.274 2.053 1.88 3.503 2.27 5.498.006.02.013.062 0 .074-1.143 1.364-3.717.29-5.323-.592-.868-.477-1.137-1.241-1.48-2.111-.347-.883-.921-1.446-1.481-2.187-1.085-1.426 1.324-2.173 2.36-2.5M48.508 351.407c.685-.367 1.088-1.027 1.719-1.448.703-.474 1.602-.561 2.41-.73 1.996-.423 3.45-.312 4.889 1.332.126.147.694.436.536.538-1.601 1.03-3.275.794-4.903 1.212-.812.21-1.593.423-2.315.862-.696.42-1.342 1.016-2.216.844-.61-.123-1.765-.84-1.692-1.59.064-.65 1.109-.765 1.572-1.02" />
        <Path d="M46.796 348.202c.174-.477 1.186-.954 1.594-1.224.547-.368 1.127-.63 1.724-.907.628-.288 1.184-.74 1.823-.998 1.564-.637 2.226 1.362 3.449 2.355.06.052-.044.235-.075.309-.643 1.485-2.536.71-3.855 1.148-.654.21-1.176.796-1.823.975-.582.163-1.021-.403-1.511-.663-.397-.211-1.541-.406-1.326-.995M45.552 347.27c.8-.655 2.815-1.993 3.864-1.31-.585.195-1.423.568-1.89.956-.467.389-.891.964-1.315 1.355h-.075c-.35.074-1.808.656-2.05.309-.276-.395 1.162-1.06 1.466-1.31M66.374 358.14c.184-1.34.329-2.754.739-4.047.273-.865.743-1.51 1.724-1.337 1.295.228 2.51 1.227 3.565 1.947 1.216.833 2.496 1.842 3.952 2.21 2.939.74 4.648-2.81 6.32-4.561.838-.886 1.895-1.712 3.185-1.45 1.393.284 2.54 1.47 3.59 2.345 1.21 1.005 2.5 2.028 4.053 2.418 1.4.352 2.82.168 4.158-.285.137-.046 1.506.44 1.929.854 2.68 2.59 2.156 7.362.98 10.525-1.474 3.965-5.041 6.698-9.377 5.732-3.317-.74-5.055-3.49-7.867-5.066-3.91-2.191-8.735-2.002-12.996-3.025-1.404-.34-3.14-.836-3.817-2.256-.576-1.212-.31-2.728-.138-4.003" />
        <Path d="M64.178 342.539s.979-.285 1.246-.574c.667-.721 1.108-1.61 1.726-2.367.868-1.062 2.028-1.77 3.342-2.155 1.174-.342 1.778-.455 3.026-.404-.51 1.074-.536 2.069-1.631 2.934-2.65 2.087-5.157 1.945-4.633 6.182.549 4.436 9.469 7.857 13.15 5.54 5.178-3.252 3.482-5.66 2.488-11.706 1.233.732 1.748 1.226 2.306 2.567.425 1.02.339 2.154.68 3.198.363 1.103 1.14 1.925 1.689 2.92.485.88.096 1.253-.836 1.378-1.21.167-2.492-.026-3.67.377-1.081.365-1.537 1.267-1.984 2.243-.514 1.128-1.085 2.288-2.184 2.95-1.213.737-2.668.584-3.93.059-1.405-.585-2.58-1.57-3.725-2.54-.71-.604-1.414-1.253-2.367-1.385-1.945-.278-3.986.22-5.405-1.546-.577-.713-.857-1.624-1.22-2.456-.394-.916-.915-1.801-1.197-2.755-.668-2.276 1.372-2.528 3.129-2.46" />
      </G>
      <Path d="M0 337h102v36H0z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgLowerFoot;
