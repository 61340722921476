import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandProximalPhalanges = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M54.268 62.584c2.002.452 4.393.857 4.45-1.879.056-2.526-1.033-5.1-1.748-7.483-.705-2.347-1.206-4.712-.992-7.177.115-1.373.44-2.71.843-4.022.31-1.039.799-2.165.77-3.265-.05-2.38-3.078-2.71-4.898-2.515-2.332.256-4.374 1.59-4.215 4.142.156 2.483 1.473 4.815 1.934 7.253.436 2.325.273 4.601-.725 6.761-.91 1.987-2.333 3.955-2.246 6.25.102 2.544 2.21 3.055 4.356 2.557.633-.142 1.26-.35 1.884-.544M31.565 58.484c-.4 2.063-.27 4.017 2.085 4.562 1.973.458 5.275.522 7.055-.603 2.078-1.313.608-4.47-.02-6.21-.836-2.352-1.407-4.5-1.324-7.006.075-2.43.159-4.848.078-7.276-.017-.53.375-1.926.375-1.926-.034-1.86.144-4.638-2.287-4.882-1.86-.182-5.018.558-6.09 2.237-1.147 1.803.46 4.458.966 6.238.689 2.4.766 4.898.57 7.372-.193 2.565-.924 4.983-1.408 7.494M16.648 63.491c-.408 2.46 1.06 3.184 3.32 3.08.642-.03 1.928-.468 2.589-.5 1.809-.113 4.487.6 5.085-1.678.658-2.492-1.161-5.375-2.267-7.472-1.256-2.385-2.164-4.605-1.623-7.334.486-2.457 1.992-5.026 1.649-7.58-.29-2.16-2.588-2.27-4.795-2.246-2.202.024-4.318.174-4.958 2.005-.796 2.274.697 3.68 1.444 5.83.803 2.342 1.396 6.058 1.294 8.582-.101 2.552-1.338 4.83-1.738 7.313M10.042 72.442c1.894.298 3.324-.119 2.542-2.32-.635-1.812-2.061-3.237-2.896-4.95-.784-1.594-1.651-3.553-1.49-5.369.138-1.548 1.687-3.463 1.203-5.004-.483-1.524-3.117-1.037-4.29-.918-1.548.144-3.416.455-3.417 2.383.002 1.856 1.309 3.56 1.785 5.31.474 1.735.793 3.654.96 5.441.102 1.09-.012 2.141-.19 3.211-.131.78-.448 1.862.023 2.584 1.028 1.576 3.84.17 5.05-.4" />
    </G>
  </Svg>
);
export default SvgHandProximalPhalanges;
