import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerLegFull = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 136"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M14.329.034c-2.081-.218-2.631.658-4.074 1.312-1.461 2.342-2.863 6.081-1.891 9.455.535 1.852 3.156 4.461 4.656 4.799.841.188 1.511-.141 2.325 0C21.273 13.037 22.353.89 14.329.034" />
      <Path d="M21.164 9.2c-1.306 3.058-2.73 5.998-5.381 7.709-6.353 1.364-6.388-4.94-9.456-6.837C4.306 8.823.024 9.228-.362 11.528-.629 13.111.82 15.3 1.383 16.473c2.296 4.771 4.145 10.045 5.527 16.144 2.826 12.467 1.678 27.614 1.454 42.328-.153 10.034-.794 19.56-1.163 28.653-.344 8.47-1.214 15.51-3.637 22.255-.559 1.559-1.92 3.465-1.647 5.939.322 2.92 2.847 2.54 5.138 2.06.806.141 2.797-.542 4.478 0 1.387.447 2.44 2.148 3.523 2.148.727 0 1.785-2.03 1.745-2.793-.097-1.833-.612-4.288-.728-5.177-.307-2.347-.898-4.475-1.017-6.252-.506-7.547.525-14.884.144-22.4-.355-7.039.437-14.159.437-21.96 0-11.603.646-22.172 1.164-33.017.365-7.647.197-15.867 2.762-21.675 1.335-3.013 4.184-4.711 5.674-7.416.888-1.615 2.257-5.427-.437-6.11-1.138-.292-2.478.215-3.636 0" />
      <Path d="M25.964 15.02c-.125-.029-.137.054-.146.145-1.292 1.453-1.775 3.119-2.909 4.798-.887 1.31-2.578 2.424-3.054 3.782-1.282 3.664-.038 10.251 0 14.837.031 3.936-.292 8.071-.292 12.218 0 12.324-.939 24.25-1.745 35.489-.11 1.547-.618 3.25-.726 4.655-.411 5.28-.633 10.79-.873 16.289-.244 5.537-.839 11.279-.436 16.584.306 4.029 1.914 8.03 1.599 12.215 3.162-1.127 2.219-6.547 1.892-10.47-1.086-13.049 1.018-25.064 1.6-37.526.385-8.299.953-16.732 1.453-25.017.525-8.695.271-17.263.582-25.601.127-3.382.197-7.116.872-10.328.574-2.72 2.188-4.299 3.491-6.542.041-2.324.161-4.72-1.308-5.528" />
    </G>
  </Svg>
);
export default SvgLowerLegFull;
