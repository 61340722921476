import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgForearmUlnaProximal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 85"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M27.05 2.574c.09 2.762-1.935 2.6-2.68 10.624-.104 1.119-.221 2.396-.346 3.783-.094 1.049-9.357-.14-8.97-.81 1.667-2.888-.69-9.903-.228-10.52 2.353-3.16-.303-3.485 2.591-4.573 2.575-.966 2.684.902 3.84 1.227 2.765.773 5.703-2.496 5.793.27" />
      <Path d="M15 0h12v17H15z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgForearmUlnaProximal;
