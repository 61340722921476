import { useEffect, useCallback, useMemo } from 'react'
import { StyleSheet, ScrollView, View, Image } from 'react-native'
import { useRoute, useNavigation, useTheme } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import pluralize from 'pluralize'
import { startCase } from 'lodash'
import { useStores } from 'stores'
import { RefreshControl, Card, Alert, Gallery, Text, Icon, Feedback } from 'components'
import { Institution } from 'components/fracture'
import { HTMLRenderer } from 'components/HTMLRenderer'
import { Theme } from 'styles'

const Fracture = observer(() => {
  const { rootStore } = useStores()
  const { contentStore, appStore } = rootStore

  const navigation = useNavigation()
  const { colors } = useTheme()

  const { params } = useRoute()
  const { extremity, region, bone, fracture } = params

  const content = contentStore.fractureBySlug(fracture)
  const splints = useMemo(() => {
    if (content.splints.length) {
      return content.splints
        .map((splint) => contentStore.splintById(splint.id))
        .filter((splint) => splint !== undefined)
    } else return []
  }, [content.splints])

  useEffect(function setTitle() {
    navigation.setOptions({
      title: title,
    })
  })

  const title = useMemo(() => {
    if (!bone) {
      return content.title.full
    } else {
      return content.title.short || content.title.full
    }
  })

  const handlePush = (splint, { payload }) => {
    navigation.push('splint', {
      extremity: extremity,
      region: region,
      bone: bone,
      fracture: fracture,
      splint: splint,
      payload: payload,
    })
  }

  const styles = StyleSheet.create({
    wrap: {
      padding: Theme.spacing.layout,
    },
    section: {
      marginTop: Theme.spacing.layout,
    },
    listItem: {
      flexDirection: 'row',
      marginLeft: Theme.spacing.layout,
    },
    listItemBullet: {
      paddingRight: Theme.spacing.border,
      paddingTop: 6,
    },
    listItemText: {},
  })

  return (
    <ScrollView
      contentContainerStyle={styles.wrap}
      refreshControl={<RefreshControl />}
    >
      {content.alert && <Alert content={content.alert} />}
      {splints.length > 0 && (
        <Card
          icon={'paper-roll'}
          title={pluralize('Splint', splints.length)}
          iconStyle={{ transform: [{ rotate: '90deg' }] }}
        >
          <Text>Select a method below for specific instructions:</Text>
          {splints.map((splint, index) => (
            <Card
              key={splint.slug}
              index={index}
              title={splint.title.full}
              onPress={() => handlePush(splint.slug, content.splints[index])}
              style={{
                marginTop: Theme.spacing.thin,
                backgroundColor: colors.overlay,
              }}
            />
          ))}
        </Card>
      )}
      {content.searchTerms.length > 0 && (
        <View style={styles.section}>
          <Text
            size={'h3'}
            weight={'bold'}
          >
            Also Known As:
          </Text>
          <Text>{content.searchTerms.map((term) => startCase(term)).join(', ')}</Text>
        </View>
      )}
      {content.gallery && <Gallery gallery={content.gallery} />}
      <Institution institution={content?.institution} />
      <HTMLRenderer html={content.content} />
      {content.followup.specialist && (
        <View style={styles.section}>
          <Text
            size={'h3'}
            weight={'bold'}
          >
            Follow-Up
          </Text>
          <Text>
            {content.followup.specialist} {content.followup.timeframe}
          </Text>
        </View>
      )}
      {content.references.length && (
        <View style={styles.section}>
          <Text
            size={'h3'}
            weight={'bold'}
          >
            {pluralize('Reference', content.references.length)}
          </Text>
          {content.references.map(({ reference }) => {
            return (
              <View
                style={styles.listItem}
                key={reference}
              >
                <View style={styles.listItemBullet}>
                  <Icon
                    name={'checkbox-blank-circle'}
                    size={6}
                  />
                </View>
                <View style={styles.listItemText}>
                  <Text size={'sm'}>{reference}</Text>
                </View>
              </View>
            )
          })}
        </View>
      )}
      <Feedback title={title} />
    </ScrollView>
  )
})

export default Fracture
