import * as ScreenOrientation from 'expo-screen-orientation'
import * as Device from 'expo-device'

const setTabletOrientation = async (deviceType) => {
    if (deviceType === Device.DeviceType.TABLET) {
        await ScreenOrientation.unlockAsync()
    } else {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP)
    }
}

export {
    setTabletOrientation
}