import { useMemo } from 'react'
import { useWindowDimensions, StyleSheet, View, Image } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { Logo, Progress } from 'components'
import { Theme } from 'styles'

const Loading = observer(() => {

  const { colors } = useTheme()
  const { rootStore } = useStores()
  const { contentStore } = rootStore

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      backgroundColor: colors.background,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

	return (
		<View style={styles.wrap}>
      <Logo />
      <Progress
        progress={contentStore.progress}
        margin={Theme.spacing.layout}
      />
    </View>
	)
})

export default Loading