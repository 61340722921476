import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgUpperUpperArm = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 108 268"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <G>
        <Path d="M45.761 14.512c-1.435-.386-4.256-.309-4.739 2.076-.484 2.383-1.426 8.948-.707 14.135.717 5.19-2.81 48.9-4.799 58.701-1.987 9.801-2.876 18.335-6.176 22.152-3.297 3.812-8.104 5.364-7.087 8.657 1.014 3.294 4.21 6.916 7.655 5.847 3.445-1.07 1.885-4.233 5.793-.195 3.91 4.04 4.927 2.845 7.04-.374 2.11-3.222 3.304 1.96 4.085-1.894.781-3.856-4.404-7.344-4.705-12.783-.3-5.438 6.241-73.696 9.33-77.852 3.088-4.156 4.522-2.818 6.452-5.52 2.744-3.852 2.515-8.95.661-11.57-1.006-1.42-5.188-4.869-8.722-4.048-3.534.823-4.08 2.668-4.08 2.668" />
        <Path d="M95.418 19.783c-.29-3.173-2.31-7.79-6.06-11.83-3.75-4.038-10.674.578-12.982 1.988-1.503.917-1.907 2.057-2.244 2.545-.171.248-.675 1.67-.865 2.327-.348 1.31-1.306 2.988-2.865 4.067-.763.545-1.674.932-2.58 1.032-.451.036-.894.029-1.3-.056-.21-.032-.404-.096-.572-.141-.188-.072-.378-.133-.533-.212-.325-.143-.591-.324-.826-.476a5 5 0 0 1-.576-.434c-.307-.253-.464-.418-.464-.418l.047-.072.573.23c.365.138.868.37 1.483.524 1.19.326 2.898.295 4.37-.561 1.474-.855 2.488-2.37 2.955-3.58.244-.61 1.444-3.465-.5-6.041-3.208-4.255-7.878.608-9.772 1.564-.187.095-.036 3.486-4.22 2.764-.828-.143 1.822 1.695 3.264 4.725s1.567 8.105-2.705 11.522c-2.975 2.38.163 1.497 4.202 4.526 4.04 3.03 13.417 34.623 19.33 35.344 5.915.722 9.81-11.541 10.532-16.59s2.597-29.573 2.308-32.747" />
        <Path d="M57.15 9.049c-1.817-.526-.742-1.368-.354-2.652.48-1.597-.667-2.725-2.252-2.782-1.62-.06-2.994 1.026-4.335 1.778-1.301.73-7.6 3.384-5.666 5.686.965 1.149 3.342.065 4.435-.285 1.59-.51 3.146-.66 4.78-.238 3.016.776 6.513 4.008 8.625-.15 0 0-3.67-.905-5.232-1.357" />
        <Path d="M56.035 2.766c.722.283 1.94 3.602 1.836 4.323-.105.72 3.068-1.62 8.118-1.443 5.049.176 17.166-.177 21.35.689s10.243 2.097 14.138 1.714 5.049-2.863 5.482-4.437c.433-1.573 1.299-4.747-.865-3.592s-5.855 4.225-10.646 3.916c-4.791-.31-12.621-1.67-18.79-2.39-8.125-.95-20.623 1.22-20.623 1.22" />
      </G>
      <Path d="M15 0h93v70.085H57.87V128H15z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgUpperUpperArm;
