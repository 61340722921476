import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerLeg = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 102 373"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <G>
        <Path d="M85.849 318.28c1.454.329 5.478 6.225 6.015 8.09 1.246 4.33-4.18 6.095-7.881 5.39 2.495-2.882.756-9.63 1.866-13.48M75.143 325.574c.066 1.119.13 2.239.15 3.357-.4-.714-.375-1.931-.15-3.357" />
        <Path d="M61.82 204.799c9.292 5.808 15.71-5.744 18.32 2.15l-2.948.588c-3.871-.074-10.661.565-11.762 4.766-1.041 3.974 4.92 8.65 5.867 12.33 1.999 7.76 2.16 42.593 2.983 51.912 1.612 18.243 1.358 50.843.857 54.02-4.43.92.755-15.146-7.521-64.666-1.55-9.278-2.926-18.33-5.532-27.433-2.08-7.267-7.118-37.953-.264-33.667" />
        <Path d="M72.486 340.748c2.39-1.882 2.154-5.102 3.253-7.848 4.202-10.493-2.422-101.622-2.651-107.294-.105-2.576-.14-5.488-2.005-7.442-1.618-1.696-4.644-1.296-4.272-4.485.682-5.815 10.28-4.872 13.918-4.373 5.21 2.849-1.761 12.874-1.74 16.509.03 5.156 5.26 34.603 5.297 44.086.018 5.23 1.18 25.128.51 30.134-1.275 9.508-.272 19.612-2.311 29.24-2.935 13.854 5.133 17.234-2.345 21.938-3.682 2.314-11.158-1.003-11.706-5.44-.525-4.236 1.404-2.938 4.052-5.025" />
      </G>
      <Path d="M45 204h57v148H45z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgLowerLeg;
