import { StyleSheet, ScrollView, View } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { RefreshControl, Text, Button, Card, Switch } from 'components'
import { routeMap } from 'screens'
import { Theme } from 'styles'

const Settings = observer(() => {
  const { rootStore } = useStores()
  const { contentStore, appStore } = rootStore

  const { colors } = useTheme()

  const styles = StyleSheet.create({
    wrap: {
      padding: Theme.spacing.layout,
    },
    card: {
      marginBottom: Theme.spacing.layout,
    },
    content: {
      paddingVertical: Theme.spacing.thin,
    },
  })

  const handleRefresh = async () => {
    await contentStore.clearCache()
    contentStore.loadContent(true)
  }

  const toggleInstitution = (value) => {
    if (appStore.institution === value) {
      appStore.setInstitution(null)
    } else {
      appStore.setInstitution(value)
    }
  }

  return (
    <ScrollView
      contentContainerStyle={styles.wrap}
      refreshControl={<RefreshControl clearCache={true} />}
    >
      <Card
        title={'Refresh Content'}
        style={styles.card}
      >
        <View style={styles.content}>
          <Text>
            Fracture information is automatically refreshed periodically. To force a refresh, press
            the button below.
          </Text>
          <Text
            style={{ color: colors.notification }}
            weight={'bold'}
          >
            Note: This will delete all currently stored data.
          </Text>
        </View>
        <Button
          text={'Refresh'}
          onPress={handleRefresh}
          disabled={contentStore.refreshing}
        />
      </Card>
      <Card
        title={'Institutional Content'}
        style={styles.card}
      >
        <View style={styles.content}>
          <Text>
            Select institution-specific content you’d like included in fracture recommendations.
          </Text>
          <View style={styles.option}>
            {appStore.institutions.map((institution) => (
              <Switch
                key={institution.value}
                label={institution.name}
                value={appStore.institution === institution.value}
                onChange={() => toggleInstitution(institution.value)}
              />
            ))}
          </View>
        </View>
      </Card>
    </ScrollView>
  )
})

export default Settings
