import { StyleSheet, View, TextInput } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { Icon } from 'components'
import { Theme } from 'styles'

const Search = ({
  search = '',
  setSearch = () => null,
}) => {
  const { colors } = useTheme()

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      backgroundColor: colors.background + Theme.opacity.opaque,
    },
    search: {
      flex: 1,
      flexDirection: 'row',
      marginHorizontal: Theme.spacing.layout,
      marginVertical: Theme.spacing.thin,
      backgroundColor: colors.card,
      alignItems: 'center',
      borderRadius: Theme.spacing.border,
    },
    inputIcon: {
      paddingHorizontal: Theme.spacing.thin,
      fontSize: Theme.font.size.h2,
      color: colors.border,
    },
    input: {
      paddingVertical: Theme.spacing.thin,
      fontSize: Theme.font.size.h4,
      fontFamily: Theme.font.family.regular,
      color: colors.text,
      flex: 1,
    },
  })

  return (
    <View style={styles.wrap}>
      <View style={styles.search}>
        <Icon name={'magnify'} style={styles.inputIcon} />
        <TextInput
          style={styles.input}
          placeholder={'Search'}
          value={search}
          onChangeText={setSearch}
        />
        {search && <Icon onPress={() => setSearch('')} name={'close'} style={styles.inputIcon} />}
      </View>
    </View>
  )
}

export default Search