import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootMiddlePhalanges = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M41.209 19.09c-.031.712-.611 1.21-.655 1.887-.096 1.483 2.238 1.26 3.149 1.179.458-.04 1.255-.15 1.519-.607.268-.467.034-1.353-.048-1.857-.027-.171-.23-.67 0-.977 1.055-1.405.274-2.332-1.413-2.144-.771.086-1.21.116-1.96-.077-.632-.164-1.16-.065-1.084.744.062.655.52 1.154.492 1.852M31.1 13.718c.03.769-.51 1.348-.497 2.08.025 1.606 2.334 1.187 3.237 1.032.453-.08 1.239-.26 1.464-.77.23-.523-.076-1.461-.2-1.998-.041-.18-.284-.702-.08-1.05.937-1.593.08-2.531-1.585-2.201-.762.152-1.196.215-1.96.066-.645-.13-1.162.018-1.021.885.115.7.616 1.2.643 1.956M20.304 11.664c.087.806-.406 1.453-.338 2.22.149 1.688 2.422 1.1 3.31.877.447-.112 1.217-.351 1.403-.905.189-.564-.19-1.532-.352-2.087-.055-.188-.34-.723-.16-1.102.812-1.738-.114-2.668-1.753-2.214-.748.21-1.177.303-1.951.195-.654-.094-1.16.095-.951.997.168.73.706 1.225.792 2.019" />
      <Path d="M49.817 28.257c-.03.688-.611 1.17-.655 1.822-.096 1.435 2.238 1.218 3.149 1.14.459-.038 1.256-.145 1.52-.586.267-.451.033-1.308-.05-1.795-.025-.164-.228-.647 0-.944 1.057-1.357.275-2.253-1.412-2.07-.771.082-1.21.11-1.96-.078-.632-.156-1.16-.06-1.084.72.062.634.52 1.116.492 1.79" />
    </G>
  </Svg>
);
export default SvgLowerFootMiddlePhalanges;
