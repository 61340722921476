import { useState, useEffect, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useTheme, useNavigation, useRoute } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { camelCase, split } from 'lodash'
import { Text, Button, Selector } from 'components'
import { Theme } from 'styles'
import { Model } from 'assets/model'

const Region = observer(() => {
  const { rootStore } = useStores()
  const { contentStore } = rootStore

  const { colors } = useTheme()
  const navigation = useNavigation()
  const { params } = useRoute()
  const { extremity, region } = params

  const [ current, setCurrent ] = useState(null)
  const [ bone, segment ] = useMemo(() => split(current, '.'), [current])
  const model = Model[camelCase(extremity)][camelCase(region)]
  const taxonomy = contentStore.taxonomyBySlug(region)

  useEffect(function setTitle() {
    navigation.setOptions({
      title: taxonomy.title.full,
    })
  })

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      padding: Theme.spacing.layout,
    },
    button: {
      marginTop: Theme.spacing.thin,
    },
  })

  const handlePush = () => {
    let fractures = contentStore.fracturesByBone(bone, segment)

    if (!fractures.length) {
      return null
    }
    else if (fractures.length === 1) {
      navigation.push('fracture', {
        extremity: extremity,
        region: region,
        bone: bone,
        fracture: fractures[0].slug,
      })
    }
    else {
      navigation.push('bone', {
        extremity: extremity,
        region: region,
        bone: bone,
        segment: segment ?? '',
      })
    }
  }

  return (
    <View style={styles.wrap}>
      <Selector
        model={model}
        current={current}
        setCurrent={setCurrent}
        handlePush={handlePush}
        placeholder={'Select a Bone'}
      />
    </View>
  )
})

export default Region