import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandMetacarpals = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M45.772 108.573c3.659.32 6.507-.303 6.799-3.612.089-.986-.456-3.109-.654-3.902-.928-3.593-.431-7.09-.038-10.725.395-3.668.027-7.466.729-11.099.727-3.757 3.303-6.642 4.358-10.244 1.076-3.656-2.067-5.419-5.33-5.532-3.274-.1-6.575 1.345-6.575 5.01-.006 1.866.535 3.731.91 5.55.463 2.244.816 4.498.904 6.794.19 4.717-.58 9.526-2.486 13.867-.821 1.88-1.238 3.661-2.271 5.431-.786 1.365-1.663 2.665-1.77 4.28-.227 3.396 2.513 3.932 5.424 4.182" />
      <Path d="M39.01 102.3c-1.88.472-5.965 1.96-7.395-.145-.667-.976-.386-2.38-.152-3.448.4-1.797 1.036-3.54 1.52-5.313 2.03-7.45 1.257-14.775-1.364-21.947-.88-2.392-1.695-5.299.949-6.888 2.362-1.412 6.62-1.308 8.284 1.173 1.614 2.413-.115 5.84-.668 8.335-.67 3.03-.626 6.184-.873 9.272-.262 3.377-.326 6.545.784 9.801.857 2.506 2.157 4.964.629 7.486-.311.507-.683.98-1.05 1.447a.8.8 0 0 1-.327.062M19.502 76.503c1.435 2.456 1.504 7.144 1.437 8.031.274 1.758 1.035 7.547 1.095 9.845.074 2.805-1.502 6.402-.437 9.087 1.026 2.585 5.81 2.73 7.999 1.208 2.697-1.872.314-4.55-.683-6.794-.443-.994-.746-3.65-.906-4.268-1.54-5.91-1.33-10.454-.603-16.453.344-2.788.524-5.914-1.894-7.876-1.995-1.618-6.097-2.283-7.866.073-1.698 2.26.725 5.224 1.858 7.147" />
    </G>
  </Svg>
);
export default SvgHandMetacarpals;
