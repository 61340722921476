import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { Pressable, Text, Icon } from 'components'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTheme } from '@react-navigation/native'
import { getHeaderTitle } from '@react-navigation/elements'
import { Theme } from 'styles'

const Header = ({ navigation, route, options, back }) => {
  const { colors } = useTheme()

  const title = getHeaderTitle(options, route.name)
  const { top } = useSafeAreaInsets()

  const styles = StyleSheet.create({
    wrap: {
      flexDirection: 'row',
      paddingTop: top,
      paddingHorizontal: Theme.spacing.layout,
      backgroundColor: colors.background,
    },
    title: {
      flex: 1,
      fontSize: Theme.font.size.h1,
      fontFamily: Theme.font.family.bold,
      textTransform: 'capitalize',
    },
    back: {
      justifyContent: 'center',
      marginLeft: -Theme.spacing.layout,
      paddingHorizontal: Theme.spacing.layout,
    },
    icon: {},
  })

  const handleBack = () => {
    navigation.goBack()
  }

  if (options.hideHeader) {
    return null
  } else {
    return (
      <View style={styles.wrap}>
        {back && 
          <Pressable
            onPress={handleBack}
            style={styles.back}
          >
            <Icon name={'chevron-left'} style={styles.icon} size={'h1'} />
          </Pressable>
        }
        <Text
          style={styles.title}
          numberOfLines={1}
        >
          {title}
        </Text>
      </View>
    )
  }
}

export default Header