import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootMetatarsals = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M38.93 47.845c1.243 3.693 1.19 5.556 1.038 10.445-.116 3.788-.285 11.837.583 13.995 1.312 3.263 2.446 4.373 1.852 7.91-.674 4.017-1.262 5.475 1.704 7.353.595.375 2.496 1.713 3.17 1.52.168-.046.256-.456.36-.596 1.349-1.812.482-6.021-.261-9.088-.9-3.713-.998-7.965-1.722-11.723-.762-3.957-1.5-7.4-1.304-11.44.204-4.227.056-8.403.28-12.646.208-3.906-1.695-7.338-4.783-5.275-2.868 1.92-1.82 6.862-.917 9.545" />
      <Path d="M41.369 79.679c-.097.954.45 3.16-.457 3.743-1.043.672-3.103-.941-3.991-1.523-2.89-1.894-3.706-.395-3.584-3.857.057-1.661-.618-7.98-.506-9.636.142-2.122.085-4.25-.015-6.371-.205-4.406-.245-9.036-1.4-13.32-.834-3.09-2.438-7.104-1.731-10.415.6-2.81 2.945-4.438 5.408-2.584 2.626 1.98 1.172 6.397.593 8.88-.922 3.957.368 7.612 1.469 11.389 1.19 4.098 1.88 8.244 2.529 12.453.47 3.046 1.102 6.063 1.403 9.132.072.733.13 1.467.19 2.2M30.689 82.509c1.617.965 2.92-.456.73-10.132s-4.944-23.915-4.206-27.93c.738-4.017 1.025-8.633-.122-10.057-1.146-1.421-5.53-3.362-6.717 1.567s-.182 4.017 1.005 7.21c1.187 3.196 2.817 12.964 2.686 19.079-.131 6.114-.68 10.953-1.317 12.23-.64 1.279-.457 3.56.365 4.656.82 1.095 4.655 1.634 7.576 3.377" />
    </G>
  </Svg>
);
export default SvgLowerFootMetatarsals;
