import { Pressable as RNPressable } from 'react-native'
import { throttle } from 'lodash'
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated'
import { withHaptic } from 'utils'

const AnimatedPressable = Animated.createAnimatedComponent(RNPressable)

const Pressable = ({ animated = true, haptic = true, children = null, ...props }) => {
  const handlePress = throttle(() => {
    haptic && withHaptic()
    props.onPress()
  }, 300)

  const opacity = useSharedValue(1)

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
    }
  })
  const easing = Easing.inOut(Easing.cubic)

  const fadeIn = () => {
    opacity.value = withTiming(0.5, {
      duration: 50,
      easing: easing,
    })
  }

  const fadeOut = () => {
    opacity.value = withTiming(1, {
      duration: 100,
      easing: easing,
    })
  }

  return (
    <AnimatedPressable
      onPress={handlePress}
      onPressIn={fadeIn}
      onPressOut={fadeOut}
      disabled={props.disabled}
      style={[props.style, animated && animatedStyle]}
      children={children}
    />
  )
}

export default Pressable
