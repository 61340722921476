import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandFifthMetacarpal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M6.5 82.713c1.645 2.46 2.714 4.87 3.385 7.753.71 3.06 1.52 6.195 1.69 9.34.086 1.43-.036 2.842-.125 4.266-.064 1.051-.176 2.287.435 3.214 1.465 2.206 5.577 1.245 7.438.198 3-1.696.978-3.85-.434-6.052-2.591-4.046-5.452-12.835-4.854-18.604.287-2.768.669-5.985-1.584-8.088-2.044-1.906-5.857-1.973-7.571.43-1.763 2.482.203 5.438 1.62 7.543" />
  </Svg>
);
export default SvgHandFifthMetacarpal;
