import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerLegTibiaDistal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 136"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M5.889 117.016c-.548 3.057-1.296 5.974-2.325 8.837-.559 1.559-1.92 3.465-1.647 5.939.322 2.92 2.847 2.54 5.138 2.06.806.141 2.797-.542 4.478 0 1.387.447 2.44 2.148 3.523 2.148.727 0 1.785-2.03 1.745-2.793-.097-1.833-.612-4.288-.728-5.177-.307-2.347-.898-4.475-1.017-6.252a69 69 0 0 1-.143-4.514c0-.917-8.845-1.247-9.024-.248" />
      <Path d="M0 136h16v-20H0z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgLowerLegTibiaDistal;
