import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootNavicular = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M8.989 108.783c.324 1.639 1.462 3.925 3.186 2.076 1.676-1.798 2.065-4.458 3.674-6.322 1.809-2.093 4.805-2.52 7.427-2.382 2.675.139 4.905 1.068 7.048 2.626l.183.09c.229.26.44.677.897.533.18-.476.36-.952.52-1.436.638-1.937.94-4.167-.235-5.971-1.252-1.921-3.596-2.422-5.733-2.573-2.212-.156-4.397-.27-6.602-.532-2.24-.267-4.538-.264-6.551.889-4.188 2.398-4.653 8.759-3.814 13.002" />
  </Svg>
);
export default SvgLowerFootNavicular;
