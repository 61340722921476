import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgForearmRadiusDistal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 85"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M3.197 63.622q-.12 1.3-.279 2.538C2.23 71.596 1.106 76.05.844 78.555c-.353 3.376-1.879 7.41 3.34 5.041 6.017-2.73 8.667 1.151 7.682-3.268-.483-2.167-1.052-7.632-1.493-14.1-.057-.839.122-1.887-.165-2.56-.794-1.865-6.907-1.166-7.01-.046" />
      <Path d="M0 85h16V63H0z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgForearmRadiusDistal;
