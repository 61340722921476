import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandDistalPhalanges = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M80.46 65.302c1.099.407 2.455.583 3.16 1.763.62 1.024 1.99.701 2.412-.63.38-1.226.122-2.526.24-3.784.123-1.226.668-2.276 1.37-3.275.694-.985 1.458-1.978 1.312-3.26-.117-1.083-.955-1.955-2.01-1.259-.846.55-1.358 1.621-1.855 2.467-.63 1.09-1.101 2.281-1.773 3.342-.665 1.041-1.655 1.228-2.624 1.87-1.036.688-1.775 2.194-.232 2.766M50.473 13.15c-.487.758-1.888 2.483-1.151 3.266.729.775 2.008-.438 3.379-.664 1.369-.242 2.826.505 3.26-.189 1.05-1.696-1.977-3.175-1.91-5.177.057-1.794-.02-5.09-2.713-4.82-1.027.1-1.972 1.004-2.3 1.954-.436 1.242.233 2.307.94 3.27.605.827 1.112 1.41.495 2.36M30.895 9.965c-.468 1.023-1.82 1.977-1.001 3.204.894 1.335 2.625.792 3.745.142l.82-.106c.973-.062 2.874-.1 3.219-1.298.31-1.097-1.13-1.998-1.623-2.81-.735-1.225-1.217-2.318-1.287-3.666-.066-1.33.23-2.737-.53-3.805C33.567.684 32.685-.242 31.43.057c-1.252.3-2.036 1.584-1.996 2.814.078 2.36 2.555 4.678 1.46 7.094M17.399 15.177c-.44.933-1.691 1.81-.937 2.913.828 1.21 2.432.708 3.47.117l.763-.098c.898-.058 2.664-.102 2.983-1.188.293-.99-1.036-1.8-1.49-2.536-.684-1.114-1.124-2.109-1.187-3.326-.06-1.205.218-2.477-.485-3.444-.612-.846-1.431-1.69-2.6-1.412-1.154.266-1.887 1.436-1.854 2.552.065 2.135 2.36 4.238 1.337 6.422M1.26 37.507c-.473.771-1.711 1.443-1.088 2.448.68 1.087 2.218.753 3.234.308l.713-.034c.843.006 2.492.07 2.87-.839.346-.839-.834-1.614-1.202-2.275-.554-.993-.892-1.878-.857-2.933.035-1.034.385-2.11-.194-2.984-.51-.766-1.213-1.543-2.321-1.377-1.1.167-1.87 1.12-1.916 2.084-.1 1.843 1.88 3.782.76 5.602" />
    </G>
  </Svg>
);
export default SvgHandDistalPhalanges;
