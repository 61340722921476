import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootMedialCuneiform = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M9.855 88.085c.074 1.903-.284 3.917.19 5.784.453 1.79 1.712 1.582 3.074.83 1.55-.856 2.968-1.626 4.7-2.058l.365-.456c.018-.354.036-.71.038-1.066.008-1.722-.532-3.401-.498-5.118.039-1.978 1.346-3.155 2.57-4.54 1.157-1.31 2.308-3.222 1.21-4.925-1.04-1.611-3.161-1.503-4.784-1.044-3.714 1.047-7.483 3.102-7.455 7.42.012 1.742.523 3.433.59 5.173" />
  </Svg>
);
export default SvgLowerFootMedialCuneiform;
