import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgUpperArmHumerusDistal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85 129"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M10.779 103.09c-.336 1.445-.695 2.782-1.103 3.99-.658 1.95-1.442 3.557-2.45 4.724-3.297 3.812-8.104 5.364-7.087 8.657 1.014 3.294 4.21 6.916 7.655 5.847 3.445-1.07 1.885-4.233 5.793-.195 3.909 4.04 4.927 2.845 7.04-.374 2.109-3.222 3.304 1.96 4.085-1.894.78-3.856-4.404-7.344-4.705-12.783-.032-.563.01-1.797.114-3.564.071-1.202.295-2.643.295-4.299 0-3.141-8.742-3.969-9.637-.11" />
      <Path d="M0 101h29v28H0z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgUpperArmHumerusDistal;
