import React, { useMemo } from 'react'
import { useWindowDimensions } from 'react-native'
import RenderHtml, { HTMLElementModel, HTMLContentModel } from 'react-native-render-html'
import { ImageRenderer, ButtonRenderer } from 'components/HTMLRenderer'
import { useTheme, useNavigation } from '@react-navigation/native'
import { Theme } from 'styles'

const HTMLRenderer = ({
  html = null,
  linkPress = () => null,
  baseStyles = {},
  customStyles = {},
  ...props
}) => {
	const { colors } = useTheme()
	const { width, height } = useWindowDimensions()
  const navigation = useNavigation()

	const handleLinkPress = (event, href) => {
    linkPress()
    navigation.push('browser', {
      uri: href
    })
	}

	const systemFonts = [
		Theme.font.family.light,
		Theme.font.family.regular,
    Theme.font.family.italic,
		Theme.font.family.bold,
	]

	const headerStyles = {
		fontFamily: Theme.font.family.bold,
    marginTop: Theme.spacing.layout,
    marginBottom: Theme.spacing.thin,
	}

  const baseStyle = useMemo(() => {
    return {
			fontFamily: Theme.font.family.regular,
			color: colors.text,
			fontSize: Theme.font.size.p,
      ...baseStyles,
    }
  }, [colors])

  const tagsStyles = useMemo(() => {
    return {
  		h1: headerStyles,
  		h2: headerStyles,
  		h3: headerStyles,
  		h4: headerStyles,
  		h5: headerStyles,
  		h6: headerStyles,
      p: {
        marginVertical: Theme.spacing.border,
      },
  		a: { 
        color: colors.notification,
        textDecorationColor: colors.notification
      },
      strong: {
        fontFamily: Theme.font.family.bold,
      },
      em: {
        fontFamily: Theme.font.family.italic,
      },
      table: {
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderColor: colors.text,
      },
      td: {
        padding: Theme.spacing.thin,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderColor: colors.text,
      },
      th: {
        backgroundColor: colors.border,
			  fontFamily: Theme.font.family.bold,
        padding: Theme.spacing.thin,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderColor: colors.text,
      },
      hr: {
        marginVertical: Theme.spacing.layout,
        borderWidth: 0,
        borderTopWidth: 1,
        borderColor: colors.border,
      }
    }
  }, [colors])

  const classesStyles = {
    'wp-caption': {
      backgroundColor: colors.light,
      marginBottom: Theme.spacing.thin,
    },
    'wp-caption-text': {
      margin: 0,
      padding: Theme.spacing.thin,
      fontSize: Theme.font.size.sm,
    },
    ...customStyles
  }

  const renderers = {
    img: ImageRenderer,
    button: ButtonRenderer,
  }

  const customHTMLElementModels = {
    button: HTMLElementModel.fromCustomModel({
      tagName: 'button',
      contentModel: HTMLContentModel.block,
    }),
  }

	const renderersProps = {
		a: {
			onPress: handleLinkPress
		},
    ul: {
      markerBoxStyle: { paddingTop: 2, },
    },
    ol: {
      markerBoxStyle: { paddingTop: 4, },
    },
	}

	return (
		<RenderHtml 
			source={{html}}
			contentWidth={width}
			systemFonts={systemFonts}
			baseStyle={baseStyle}
      renderers={renderers}
      customHTMLElementModels={customHTMLElementModels}
			renderersProps={renderersProps}
			tagsStyles={tagsStyles}
      classesStyles={classesStyles}
      enableCSSInlineProcessing={false}
      enableExperimentalMarginCollapsing={true}
      {...props}
		/>
	)
}

export default React.memo(HTMLRenderer)