import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgForearmFull = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 85"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M10.242.734c-3.029-.52-3.418.896-1.667 3.342 1.826 2.552 1.163 8.73-1.44 19.123-3.306 13.203-3.4 28.885-3.71 36.974-.31 8.091-2.228 15.005-2.58 18.382-.354 3.376-1.88 7.41 3.34 5.041 6.016-2.73 8.666 1.151 7.681-3.268-1.317-5.91-3.276-36.367-1.537-44.79 1.74-8.426 3.414-16.696 4.234-20.968.823-4.269-1.192-8.312.237-10.086C16.23 2.714 15.681-.9 13.514.3c-2.229 1.232-2.459.575-3.272.434" />
      <Path d="M21.256 2.305c2.766.773 5.704-2.496 5.794.27.09 2.761-1.935 2.598-2.68 10.623-.745 8.023-2.188 24.223-1.935 30.295.252 6.072-2.035 19.141-2.944 24.811-.91 5.671-1.753 6.872-1.688 9.451.042 1.61.32 6.742-1.797 6.067s-4.97-1.906-3.306-7.122c1.665-5.217 2.826-15.777 3.3-20.558.472-4.78-.279-20.342-.678-25.43-.398-5.09-1.397-12.587-.268-14.542 1.667-2.887-.69-9.902-.228-10.519 2.353-3.16-.303-3.485 2.591-4.573 2.575-.966 2.684.902 3.84 1.227" />
    </G>
  </Svg>
);
export default SvgForearmFull;
