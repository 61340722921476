import { StyleSheet, View } from 'react-native'
import { Icon, Text } from 'components'
import { HTMLRenderer } from 'components/HTMLRenderer'
import { useTheme } from '@react-navigation/native'
import { Theme } from 'styles'

const Alert = ({
  title = 'Warning',
  content = null,
  style = null,
}) => {
	const { colors } = useTheme()

  const styles = StyleSheet.create({
    wrap: {
      backgroundColor: colors.primary + Theme.opacity.light,
      padding: Theme.spacing.layout,
      borderRadius: Theme.spacing.border,
      marginBottom: Theme.spacing.layout,
    },
    title: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    icon: {
      marginRight: Theme.spacing.border,
    },
  })

  return (
    <View style={styles.wrap}>
      <View style={styles.title}>
        <Icon name={'alert'} size={'h2'} style={styles.icon} />
        <Text size={'h2'} weight={'bold'}>Warning</Text>
      </View>
      <HTMLRenderer html={content} />
    </View>
  )
}

export default Alert