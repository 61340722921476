import SvgDebugFull from 'assets/model/debug/DebugFull'
import SvgDebugRect from 'assets/model/debug/DebugRect'
import SvgDebugCirc from 'assets/model/debug/DebugCirc'

import SvgUpperFull from 'assets/model/upper/UpperFull'
import SvgUpperUpperArm from 'assets/model/upper/UpperUpperArm'
import SvgUpperForearm from 'assets/model/upper/UpperForearm'
import SvgUpperHand from 'assets/model/upper/UpperHand'

import SvgUpperArmFull from 'assets/model/upper/upperArm/UpperArmFull'
import SvgUpperArmClavicle from 'assets/model/upper/upperArm/UpperArmClavicle'
import SvgUpperArmScapula from 'assets/model/upper/upperArm/UpperArmScapula'
import SvgUpperArmHumerusProximal from 'assets/model/upper/upperArm/UpperArmHumerusProximal'
import SvgUpperArmHumerusShaft from 'assets/model/upper/upperArm/UpperArmHumerusShaft'
import SvgUpperArmHumerusDistal from 'assets/model/upper/upperArm/UpperArmHumerusDistal'

import SvgForearmFull from 'assets/model/upper/forearm/ForearmFull'
import SvgForearmRadiusProximal from 'assets/model/upper/forearm/ForearmRadiusProximal'
import SvgForearmRadiusShaft from 'assets/model/upper/forearm/ForearmRadiusShaft'
import SvgForearmRadiusDistal from 'assets/model/upper/forearm/ForearmRadiusDistal'
import SvgForearmUlnaProximal from 'assets/model/upper/forearm/ForearmUlnaProximal'
import SvgForearmUlnaShaft from 'assets/model/upper/forearm/ForearmUlnaShaft'
import SvgForearmUlnaDistal from 'assets/model/upper/forearm/ForearmUlnaDistal'

import SvgHandFull from 'assets/model/upper/hand/HandFull'
import SvgHandDistalPhalanges from 'assets/model/upper/hand/HandDistalPhalanges'
import SvgHandMiddlePhalanges from 'assets/model/upper/hand/HandMiddlePhalanges'
import SvgHandProximalPhalanges from 'assets/model/upper/hand/HandProximalPhalanges'
import SvgHandMetacarpals from 'assets/model/upper/hand/HandMetacarpals'
import SvgHandThumbMetacarpal from 'assets/model/upper/hand/HandThumbMetacarpal'
import SvgHandFifthMetacarpal from 'assets/model/upper/hand/HandFifthMetacarpal'
import SvgHandThumbProximalPhalanx from 'assets/model/upper/hand/HandThumbProximalPhalanx'
import SvgHandTrapezium from 'assets/model/upper/hand/HandTrapezium'
import SvgHandTrapezoid from 'assets/model/upper/hand/HandTrapezoid'
import SvgHandCapitate from 'assets/model/upper/hand/HandCapitate'
import SvgHandHamate from 'assets/model/upper/hand/HandHamate'
import SvgHandTriquetrum from 'assets/model/upper/hand/HandTriquetrum'
import SvgHandScaphoid from 'assets/model/upper/hand/HandScaphoid'
import SvgHandLunate from 'assets/model/upper/hand/HandLunate'
import SvgHandPisiform from 'assets/model/upper/hand/HandPisiform'

import SvgLowerFull from 'assets/model/lower/LowerFull'
import SvgLowerThigh from 'assets/model/lower/LowerThigh'
import SvgLowerLeg from 'assets/model/lower/LowerLeg'
import SvgLowerFoot from 'assets/model/lower/LowerFoot'

import SvgLowerThighFull from 'assets/model/lower/thigh/LowerThighFull'
import SvgLowerThighFemurProximal from 'assets/model/lower/thigh/LowerThighFemurProximal'
import SvgLowerThighFemurShaft from 'assets/model/lower/thigh/LowerThighFemurShaft'
import SvgLowerThighFemurDistal from 'assets/model/lower/thigh/LowerThighFemurDistal'

import SvgLowerLegFull from 'assets/model/lower/leg/LowerLegFull'
import SvgLowerLegTibiaProximal from 'assets/model/lower/leg/LowerLegTibiaProximal'
import SvgLowerLegTibiaShaft from 'assets/model/lower/leg/LowerLegTibiaShaft'
import SvgLowerLegTibiaDistal from 'assets/model/lower/leg/LowerLegTibiaDistal'
import SvgLowerLegFibulaProximal from 'assets/model/lower/leg/LowerLegFibulaProximal'
import SvgLowerLegFibulaShaft from 'assets/model/lower/leg/LowerLegFibulaShaft'
import SvgLowerLegFibulaDistal from 'assets/model/lower/leg/LowerLegFibulaDistal'
import SvgLowerLegPatella from 'assets/model/lower/leg/LowerLegPatella'

import SvgLowerFootFull from 'assets/model/lower/foot/LowerFootFull'
import SvgLowerFootDistalPhalanges from 'assets/model/lower/foot/LowerFootDistalPhalanges'
import SvgLowerFootMiddlePhalanges from 'assets/model/lower/foot/LowerFootMiddlePhalanges'
import SvgLowerFootProximalPhalanges from 'assets/model/lower/foot/LowerFootProximalPhalanges'
import SvgLowerFootMetatarsals from 'assets/model/lower/foot/LowerFootMetatarsals'
import SvgLowerFootFirstMetatarsal from 'assets/model/lower/foot/LowerFootFirstMetatarsal'
import SvgLowerFootFifthMetatarsal from 'assets/model/lower/foot/LowerFootFifthMetatarsal'
import SvgLowerFootMedialCuneiform from 'assets/model/lower/foot/LowerFootMedialCuneiform'
import SvgLowerFootIntermediateCuneiform from 'assets/model/lower/foot/LowerFootIntermediateCuneiform'
import SvgLowerFootLateralCuneiform from 'assets/model/lower/foot/LowerFootLateralCuneiform'
import SvgLowerFootCuboid from 'assets/model/lower/foot/LowerFootCuboid'
import SvgLowerFootNavicular from 'assets/model/lower/foot/LowerFootNavicular'
import SvgLowerFootTalus from 'assets/model/lower/foot/LowerFootTalus'
import SvgLowerFootCalcaneus from 'assets/model/lower/foot/LowerFootCalcaneus'

const Model = {
  upper: {
    full: SvgUpperFull,
    parts: {
      UpperArm: SvgUpperUpperArm,
      forearm: SvgUpperForearm,
      hand: SvgUpperHand,
    },
    upperArm: {
      full: SvgUpperArmFull,
      parts: {
        scapula: SvgUpperArmScapula,
        humerus: {
          proximal: SvgUpperArmHumerusProximal,
          shaft: SvgUpperArmHumerusShaft,
          distal: SvgUpperArmHumerusDistal,
        },
        clavicle: SvgUpperArmClavicle,
      },
    },
    forearm: {
      full: SvgForearmFull,
      parts: {
        radius: {
          proximal: SvgForearmRadiusProximal,
          shaft: SvgForearmRadiusShaft,
          distal: SvgForearmRadiusDistal,
        },
        ulna: {
          proximal: SvgForearmUlnaProximal,
          shaft: SvgForearmUlnaShaft,
          distal: SvgForearmUlnaDistal,
        },
      },
    },
    hand: {
      full: SvgHandFull,
      parts: {
        handDistalPhalanges: SvgHandDistalPhalanges,
        handMiddlePhalanges: SvgHandMiddlePhalanges,
        handProximalPhalanges: SvgHandProximalPhalanges,
        metacarpals: SvgHandMetacarpals,
        thumbMetacarpal: SvgHandThumbMetacarpal,
        fifthMetacarpal: SvgHandFifthMetacarpal,
        thumbProximalPhalanx: SvgHandThumbProximalPhalanx,
        trapezium: SvgHandTrapezium,
        trapezoid: SvgHandTrapezoid,
        capitate: SvgHandCapitate,
        hamate: SvgHandHamate,
        triquetrum: SvgHandTriquetrum,
        scaphoid: SvgHandScaphoid,
        lunate: SvgHandLunate,
        pisiform: SvgHandPisiform,
      },
    },
  },
  lower: {
    full: SvgLowerFull,
    parts: {
      thigh: SvgLowerThigh,
      leg: SvgLowerLeg,
      foot: SvgLowerFoot,      
    },
    thigh: {
      full: SvgLowerThighFull,
      parts: {
        femur: {
          proximal: SvgLowerThighFemurProximal,
          shaft: SvgLowerThighFemurShaft,
          distal: SvgLowerThighFemurDistal,
        },
      },
    },
    leg: {
      full: SvgLowerLegFull,
      parts: {
        tibia: {
          proximal: SvgLowerLegTibiaProximal,
          shaft: SvgLowerLegTibiaShaft,
          distal: SvgLowerLegTibiaDistal,
        },
        fibula: {
          proximal: SvgLowerLegFibulaProximal,
          shaft: SvgLowerLegFibulaShaft,
          distal: SvgLowerLegFibulaDistal,
        },
        patella: SvgLowerLegPatella,
      },
    },
    foot: {
      full: SvgLowerFootFull,
      parts: {
        footDistalPhalanges: SvgLowerFootDistalPhalanges,
        footMiddlePhalanges: SvgLowerFootMiddlePhalanges,
        footProximalPhalanges: SvgLowerFootProximalPhalanges,
        metatarsals: SvgLowerFootMetatarsals,
        firstMetatarsal: SvgLowerFootFirstMetatarsal,
        fifthMetatarsal: SvgLowerFootFifthMetatarsal,
        medialCuneiform: SvgLowerFootMedialCuneiform,
        intermediateCuneiform: SvgLowerFootIntermediateCuneiform,
        lateralCuneiform: SvgLowerFootLateralCuneiform,
        cuboid: SvgLowerFootCuboid,
        navicular: SvgLowerFootNavicular,
        talus: SvgLowerFootTalus,
        calcaneus: SvgLowerFootCalcaneus,
      },
    },
  },
  debug: {
    full: SvgDebugFull,
    parts: {
      rect: SvgDebugRect,
      circ: SvgDebugCirc,
    },
  }
}

export {
  Model
}