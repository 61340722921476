import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandTrapezium = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M50.982 119.597c.338 1.731.056 4.432 2.558 3.027 1.282-.72 3.328-2.048 3.592-3.484.053-.29-.12-.882-.151-1.17-.111-1.145-2.27-2.55-3.053-3.448-.971-1.116-3.344-2.675-4.028-.37-.42 1.432.8 3.983 1.082 5.445" />
  </Svg>
);
export default SvgHandTrapezium;
