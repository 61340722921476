import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { Debug } from 'screens'
import { Header } from 'components/navigation'

const Stack = createNativeStackNavigator()

const DebugStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: (props) => <Header {...props} />
      }}
    >
      <Stack.Screen
        name={'debug'}
        component={Debug}
        options={{ title: 'Debug' }}
      />
    </Stack.Navigator>
  )
}

export default DebugStack