import { StyleSheet, View } from 'react-native'
import { Text } from 'components'
import { Theme } from 'styles'

const StopLights = ({ stoplights }) => {
  const colors = {
    red: '#FF5F56',
    yellow: '#FFBD2E',
    green: '#27C93F',
  }

  const headers = {
    red: 'Full Ortho Consult',
    yellow: 'Ortho Consult by Phone',
    green: 'ED Management',
  }

  const styles = StyleSheet.create({
    row: {
      flexDirection: 'row',
      marginTop: Theme.spacing.thin,
    },
    stopLight: {
      alignSelf: 'flex-start',
      width: Theme.spacing.layout,
      height: Theme.spacing.layout,
      borderRadius: Theme.spacing.thin,
      marginRight: 7,
      marginTop: 2,
    },
    text: {
      flex: 1,
    }
  })

  if (!stoplights) return null
  return stoplights.map((item, index) => {
    return (
      <View
        key={index}
        style={styles.row}
      >
        <View style={[styles.stopLight, { backgroundColor: colors[item.color] }]} />
        <View style={styles.text}>
          <Text
            weight={'medium'}
            style={{ color: colors[item.color] }}
          >
            {headers[item.color]}
          </Text>
          <Text>{item.condition}</Text>
        </View>
      </View>
    )
  })
}

export default StopLights
