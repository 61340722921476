import { View } from 'react-native'
import Icon from 'components/Icon'

const Empty = () => {
	return (
		<View style={{
		  flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
		}}>
      <Icon name={'cancel'} size={'h1'} />
    </View>
	)
}

export default Empty