import { useState, useEffect, useCallback, useMemo } from 'react'
import { StyleSheet, ScrollView, Modal, View } from 'react-native'
import { useRoute, useNavigation, useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { RefreshControl, Text, Pressable, Icon, Card, Image, Feedback } from 'components'
import { HTMLRenderer } from 'components/HTMLRenderer'
import { Theme } from 'styles'

const Splint = observer(() => {
  const { rootStore } = useStores()
  const { contentStore } = rootStore

  const { colors } = useTheme()
  const { bottom } = useSafeAreaInsets()

  const navigation = useNavigation()
  const { params } = useRoute()
  const { fracture, splint, payload } = params
  const content = contentStore.splintBySlug(splint)
  const basics = contentStore.pageBySlug('splinting')
  const [ modalVisible, setModalVisible ] = useState(false)

  useEffect(function setTitle() {
    navigation.setOptions({
      title: content.title.full,
    })
  })

  const handleOpen = () => setModalVisible(true)
  const handleClose = () => setModalVisible(false)

  const styles = StyleSheet.create({
    wrap: {
      padding: Theme.spacing.layout,
    },
    payload: {
      marginBottom: Theme.spacing.layout,
    },
    imageWrap: {
      marginBottom: Theme.spacing.layout,
    },
    image: {
      width: '100%',
      height: undefined,
      borderRadius: Theme.spacing.border,
      marginBottom: Theme.spacing.thin,
    },
    modal: {
      flex: 1,
      backgroundColor: colors.background,
    },
    modalHeader: {
      padding: Theme.spacing.layout,
      paddingBottom: 0,
      flexDirection: 'row',
    },
    modalTitle: {
      flex: 1,
    },
    modalButton: {
      justifyContent: 'center'
    },
    modalContent: {
      paddingHorizontal: Theme.spacing.layout,
      paddingBottom: bottom,
    },
  })


  const renderImage = () => {

    const { width, height, url, alt, description } = content.image
    const aspectRatio = useMemo(() => {
      return width / height
    })

    return (
      <View style={styles.imageWrap}>
        <Image
          source={{uri: url}}
          style={[styles.image, { aspectRatio: aspectRatio }]}
          contentFit={'contain'}
          alt={alt}
        />
        {description && <HTMLRenderer html={description} baseStyles={{ fontSize: Theme.font.size.sm }} />}
      </View>
    )
  }

  return (
    <ScrollView
      contentContainerStyle={styles.wrap}
      refreshControl={<RefreshControl />}
    >
      {content.image && renderImage()}
      {payload &&
        <Card
          title={contentStore.fractureBySlug(fracture).title.full}
          icon={'information-outline'}
          style={styles.payload}
        >
          <HTMLRenderer html={payload} />
        </Card>
      }
      {content.basics &&
        <>
          <Card
            title={'Splinting Basics'}
            description={'Get a summary of the basics of applying a splint using plaster or fiberglass.'}
            onPress={handleOpen}
          />
          <Modal
            visible={modalVisible}
            presentationStyle={'pageSheet'}
            animationType={'slide'}
            onRequestClose={handleClose}
          >
            <View style={styles.modal}>
              <View style={styles.modalHeader}>
                <Text style={styles.modalTitle} size={'h1'} numberOfLines={1} weight={'bold'}>{basics.title.full}</Text>
                <Pressable onPress={handleClose} style={styles.modalButton}>
                  <Icon name={'close'} size={'h1'} />
                </Pressable>
              </View>
              <ScrollView contentContainerStyle={styles.modalContent}>
                <HTMLRenderer html={basics.content} />
              </ScrollView>
            </View>
          </Modal>
        </>
      }
      <HTMLRenderer html={content.content} />
      <Feedback title={content.title.full} />
    </ScrollView>
  )
})

export default Splint