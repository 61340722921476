import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent as logFirebaseEvent } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyBMtKhYU6kHYcGupoT-byqLz6wqO2sFous',
  authDomain: 'fractures-7fcab.firebaseapp.com',
  projectId: 'fractures-7fcab',
  storageBucket: 'fractures-7fcab.appspot.com',
  messagingSenderId: '643319870372',
  appId: '1:643319870372:web:0a6e7b8b7f91efd5c6c731',
  measurementId: 'G-L07P39XDHP'
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const logEvent = async (name) => {
  await logFirebaseEvent(analytics, name)
}

const logScreen = async (name) => {
  await logFirebaseEvent(analytics, 'screen_view', {
    firebase_screen: name, 
  })
}

export {
  logEvent,
  logScreen,
}