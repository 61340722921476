import { useMemo, useState } from 'react'
import { StyleSheet, Pressable, View } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { Button, Text } from 'components'
import { kebabCase, split } from 'lodash'
import { withHaptic } from 'utils'
import { Theme } from 'styles'

const SVG = ({
  source = null,
  ...props
}) => {
	return (
    source(props)
	)
}

const Selector = observer(({
  model = null,
  current = null,
  setCurrent = () => null,
  handlePush = () => null,
  placeholder = 'Select a Region',
  ...props
}) => {
  const { colors } = useTheme()
  const { rootStore } = useStores()
  const { contentStore } = rootStore
  const [ footerHeight, setFooterHeight ] = useState('auto')

  const handlePress = (event, slug) => {
    event.stopPropagation()
    if (slug === current) {
      handlePush()
      withHaptic('Medium')
    } else {
      setCurrent(slug)
      withHaptic()
    }
  }

  const handleClear = () => {
    if (current) {
      setCurrent(null)
      withHaptic()
    }
  }

  const handleLayout = ({ nativeEvent }) => {
    if (nativeEvent.layout.height) {
      setFooterHeight(nativeEvent.layout.height)
    }
  }

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
    },
    svg: {
      flex: 1,
      marginBottom: Theme.spacing.layout,
    },
    overlay: {
      ...StyleSheet.absoluteFill,
    },
    footer: {
      height: footerHeight,
    },
    placeholder: {
      paddingVertical: Theme.spacing.thin,
      paddingHorizontal: Theme.spacing.layout,
      borderWidth: 4,
      borderColor: 'transparent',
      backgroundColor: colors.primary + Theme.opacity.light,
    },
  })


  const title = useMemo(() => {
    let [ parent, child ] = split(current, '.')
    let parentTaxonomy = contentStore.taxonomyBySlug(parent)
    let childTaxonomy = contentStore.taxonomyBySlug(child)

    return `${parentTaxonomy?.title.full} ${childTaxonomy?.title.full ?? ''}`
  }, [current])

  const renderSvg =(name, source) => {
    return (
      <View
        key={name}
        style={styles.overlay}
        pointerEvents={'box-none'}
      >
        <SVG
          source={source}
          fill={current === name ? colors.notification : 'transparent'}
          onPress={(event) => handlePress(event, name)}
          style={{outlineWidth: 0}}
        />
      </View>
    )
  }

  return (
    <View style={styles.wrap}>
      <Pressable style={styles.svg} onPress={handleClear}>
        <SVG source={model.full} fill={colors.svg} />
        {Object.entries(model.parts).map(([name, parent]) => {
          let parentSlug = kebabCase(name)

          // parent is object containing SVG's
          if (typeof parent === 'object') {
            return Object.entries(parent).map(([name, child]) => {
              let childSlug = kebabCase(name)
              return renderSvg(`${parentSlug}.${childSlug}`, child)
            })
          }

          // parent is SVG
          else {
            return renderSvg(parentSlug, parent)
          }
        })}
      </Pressable>

      <View style={styles.footer} onLayout={handleLayout}>
        {current ?
          <Button
            text={title}
            style={styles.button}
            onPress={handlePush}
          />
          :
          <View style={styles.placeholder}>
            <Text size={'h4'}>{placeholder}</Text>
          </View>
        }
      </View>
    </View>
  )
})

export default Selector