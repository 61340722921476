import { Platform, Switch as RNSwitch, View } from 'react-native'
import { Text } from 'components'
import { useTheme } from '@react-navigation/native'
import { Theme } from 'styles'

const Switch = ({ label, value, onChange }) => {
  const { colors } = useTheme()

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: Theme.spacing.thin,
      }}
    >
      <RNSwitch
        thumbColor={Platform.OS === 'android' ? 'white' : undefined}
        activeThumbColor={'white'}
        trackColor={{ true: colors.notification }}
        onValueChange={onChange}
        value={value}
        style={{
          marginRight: 10,
        }}
      />
      <Text>{label}</Text>
    </View>
  )
}

export default Switch
