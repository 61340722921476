import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootFirstMetatarsal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M5.467 51.336c1.307 2.959 2.706 5.979 3.485 9.13 1.012 4.087.596 8.267.043 12.388-.154 1.152-.78 3.229-.222 4.35.455.913 1.612.153 2.233-.19 2.735-1.512 5.469-3.425 8.75-2.627.65.158 1.276.397 1.896.638h.183c1.618-3.676.528-5.83-1.018-8.783-1.987-3.793-4.101-7.266-4.615-11.618-.271-2.302-.153-4.618-.003-6.924.127-1.977.34-4.04-.08-6-.784-3.668-4.286-5.026-7.74-4.781-2.89.206-6.423 1.619-6.361 5.015.058 3.237 2.175 6.516 3.449 9.402" />
  </Svg>
);
export default SvgLowerFootFirstMetatarsal;
