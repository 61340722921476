import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootIntermediateCuneiform = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M19.384 92.666c1.696 2.322 5.945 1.542 8.415 1.526.182-.001.584.11.73 0 1.327-.993-.56-2.451-.602-3.987-.054-1.854.022-2.78.914-4.37 1.713-3.055-.684-3.896-3.447-4.738-3.466-1.054-4.7.472-5.335 3.705-.466 2.37-2.324 5.606-.675 7.864" />
  </Svg>
);
export default SvgLowerFootIntermediateCuneiform;
