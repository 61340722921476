import { StyleSheet, View } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { Theme } from 'styles'


const Browser = () => {
  const { params } = useRoute()

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
    },
    webView: {
      width: '100%', height: '100%',
    },
  })

  return (
    <View style={styles.wrap}>
      <iframe src={params.uri} style={styles.webView} frameBorder={0} />
    </View>
  )

}

export default Browser