import React, { useState, useMemo } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { useInternalRenderer } from 'react-native-render-html'
import { textContent } from 'domutils'
import { useTheme, useNavigation } from '@react-navigation/native'
import { Button } from 'components'
import { Theme } from 'styles'

const ButtonRenderer = (props) => {

	const { colors } = useTheme()
  const navigation = useNavigation()

  const { tnode } = props
  const attributes = tnode.attributes
  const children = tnode.domNode.children

	const styles = StyleSheet.create({})

  const buttonText = useMemo(() => textContent(children), [children])

	const handlePress = () => {
    navigation.navigate('browser', { uri: attributes.href })
	}

  return (
    <Button
      text={buttonText}
      onPress={handlePress}
    />
  )
}

export default ButtonRenderer