import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgUpperArmScapula = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85 129"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M73.303 20.01c-.29-3.172-2.31-7.79-6.06-11.828-3.75-4.04-10.674.577-12.982 1.987-1.503.917-1.907 2.057-2.244 2.545-.171.248-.675 1.67-.865 2.327-.348 1.31-1.306 2.988-2.865 4.067-.763.545-1.674.932-2.58 1.032-.451.036-.894.029-1.3-.056-.21-.032-.404-.096-.572-.141-.188-.072-.378-.133-.533-.212-.325-.143-.591-.324-.826-.476a5 5 0 0 1-.576-.434c-.307-.253-.464-.418-.464-.418l.047-.072.573.23c.365.138.868.37 1.483.524 1.19.326 2.898.295 4.37-.561 1.474-.855 2.488-2.37 2.955-3.58.244-.61 1.444-3.465-.5-6.041-3.208-4.255-7.878.608-9.772 1.564-.187.095-.036 3.486-4.22 2.764-.828-.143 1.822 1.695 3.264 4.725s1.567 8.105-2.705 11.522c-2.975 2.38.163 1.497 4.202 4.526 4.04 3.03 13.417 34.623 19.33 35.344 5.915.722 9.81-11.541 10.532-16.59s2.597-29.573 2.308-32.747" />
      <Path d="M35.036 9.277c-1.818-.526-.743-1.368-.355-2.652.48-1.597-.667-2.725-2.252-2.782-1.62-.06-2.994 1.026-4.335 1.778-1.301.73-7.6 3.384-5.666 5.686.965 1.149 3.342.065 4.435-.285 1.59-.51 3.146-.66 4.78-.238 3.016.776 6.513 4.008 8.625-.15 0 0-3.67-.905-5.232-1.357" />
    </G>
  </Svg>
);
export default SvgUpperArmScapula;
