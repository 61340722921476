import { RefreshControl as RNRefreshControl } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'

const RefreshControl = observer(({
  clearCache = false,
  ...props
}) => {
  const { rootStore } = useStores()
  const { contentStore } = rootStore

  const handleRefresh = () => {
    clearCache && contentStore.clearCache()
    contentStore.loadContent(true)
  }

  return (
    <RNRefreshControl
      refreshing={contentStore.refreshing}
      onRefresh={handleRefresh}
      {...props}
    />
  )
})

export default RefreshControl