import { useEffect, useMemo } from 'react'
import { StyleSheet, FlatList, View } from 'react-native'
import { useRoute, useNavigation } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { Text, Button, Card, Empty } from 'components'
import { Theme } from 'styles'
import { startCase } from 'lodash'

const Bone = observer(() => {
  const { rootStore } = useStores()
  const { contentStore, appStore } = rootStore

  const navigation = useNavigation()
  const { params } = useRoute()
  const { extremity, region, bone, segment } = params
  const fractures = contentStore.fracturesByBone(bone, segment)
  const boneTaxonomy = contentStore.taxonomyBySlug(bone)
  const segmentTaxonomy = contentStore.taxonomyBySlug(segment)

  useEffect(function setTitle() {
    navigation.setOptions({
      title: `${boneTaxonomy.title.full} ${segmentTaxonomy?.title.full ?? ''}`,
    })
  })

  const styles = StyleSheet.create({
    wrap: {
      flexGrow: 1,
      padding: Theme.spacing.layout,
    },
    columnCard: {
      flex: 1 / 3,
      flexDirection: 'row',
    },
    separator: {
      width: Theme.spacing.layout,
    },
  })

  const handlePush = (fracture) => {
    navigation.push('fracture', {
      extremity: extremity,
      region: region,
      bone: bone,
      fracture: fracture,
    })
  }

  const handleColumns = useMemo(() => {
    if (appStore.isLargeScreen) {
      return {
        numColumns: 3,
      }
    }
  }, [appStore.isLargeScreen])

  const renderItem = ({ item, index }) => {
    let subtitle = ''
    if (item.searchTerms.length > 0) {
      subtitle = item.searchTerms.map((term) => startCase(term)).join(', ')
    }
    return (
      <View
        key={item.slug}
        style={appStore.isLargeScreen && styles.columnCard}
      >
        <Card
          index={index}
          title={item.title.short || item.title.full}
          subtitle={subtitle}
          image={item.image}
          onPress={() => handlePush(item.slug)}
        />
        <View style={styles.separator} />
      </View>
    )
  }

  return (
    <FlatList
      contentContainerStyle={styles.wrap}
      data={fractures}
      refreshing={contentStore.refreshing}
      onRefresh={() => contentStore.loadContent(true)}
      renderItem={renderItem}
      ListEmptyComponent={<Empty />}
      {...handleColumns}
    />
  )
})

export default Bone
