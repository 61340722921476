import { useState } from 'react'
import { useTheme, useNavigation } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { SectionList } from 'react-native'
import { Error } from 'components'
import { Item, SectionHeader, Search, listViewProps } from 'components/list'
import { Theme } from 'styles'


const Bones = observer(() => {
	const { colors } = useTheme()
  const navigation = useNavigation()

  const { rootStore } = useStores()
  const { contentStore } = rootStore

  const [ search, setSearch ] = useState('')

  const handlePush = (bone) => {
    let fractures = contentStore.fracturesByBone(bone)

    if (!fractures.length) {
      return null
    }
    else if (fractures.length === 1) {
      navigation.push('fracture', {
        bone: bone,
        fracture: fractures[0].slug,
      })
    }
    else {
      navigation.push('bone', {
        bone: bone,
      })
    }
  }

  if (contentStore.error) {
    return (
      <Error />
    )
  } else {
    return (
      <SectionList
        sections={contentStore.filteredSectionList(contentStore.structuredBones, search)}
        refreshing={contentStore.refreshing}
        onRefresh={() => contentStore.loadContent(true)}
        renderItem={({ item }) => <Item title={item.title.full} onPress={() => handlePush(item.slug)} />}
        renderSectionHeader={({ section }) => <SectionHeader section={section} />}
        ListHeaderComponent={<Search search={search} setSearch={setSearch} />}
        extraData={search}
        {...listViewProps}
      />
    )
  }
})

export default Bones