import { StyleSheet, View } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { Text, Icon } from 'components'
import { Theme } from 'styles'

const SectionHeader = ({
  section = null,
}) => {
  const { colors } = useTheme()

  const styles = StyleSheet.create({
    section: {
      backgroundColor: colors.background,
      paddingHorizontal: Theme.spacing.layout,
      paddingVertical: Theme.spacing.border,
      paddingTop: Theme.spacing.layout,
      marginTop: -1,
    },
    sectionText: {
      textTransform: 'uppercase',
      letterSpacing: 1,
      fontSize: Theme.font.size.p,
      fontFamily: Theme.font.family.bold,
    },
  })

  return (
    <View style={styles.section}>
      <Text style={styles.sectionText}>{section.title}</Text>
    </View>
  )
}

export default SectionHeader