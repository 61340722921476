import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { Fractures, Bones, Splints, Bone, Fracture, Splint, Browser } from 'screens'
import { Header, TopTabBar } from 'components/navigation'

const TopTab = createMaterialTopTabNavigator()
const Stack = createNativeStackNavigator()

const ListTabs = () => {
  return (
    <TopTab.Navigator
      tabBar={(props) => <TopTabBar {...props} />}
    >
      <TopTab.Screen
        name={'fractures'}
        component={Fractures}
        options={{
          title: 'Fractures',
        }}
      />
      <TopTab.Screen
        name={'bones'}
        component={Bones}
        options={{
          title: 'Bones',
        }}
      />
      <TopTab.Screen
        name={'splints'}
        component={Splints}
        options={{
          title: 'Splints',
        }}
      />
    </TopTab.Navigator>
  )
}

const ListStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        header: (props) => <Header {...props} />,
      }}
    >
      <Stack.Screen
        name={'listTabs'}
        component={ListTabs}
        options={{
          title: 'List',
          hideHeader: true,
        }}
      />
      <Stack.Screen
        name={'bone'}
        component={Bone}
        options={{
          title: 'Bone',
        }}
      />
      <Stack.Screen
        name={'fracture'}
        component={Fracture}
        options={{
          title: 'Fracture',
        }}
      />
      <Stack.Screen
        name={'splint'}
        component={Splint}
        options={{
          title: 'Splint',
        }}
      />
      <Stack.Screen
        name={'browser'}
        component={Browser}
        options={{
          title: 'Browser',
        }}
      />
    </Stack.Navigator>
  )
}

export default ListStack