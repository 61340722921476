import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerThighFemurShaft = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 204"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M11 168h41v-67H11z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
      <Path d="M29.88 99.25c-.18 2.972-.176 5.968-.222 8.92-.103 6.6-.302 13.208-.14 19.805.152 6 .633 11.989.779 17.989.156 6.53-.233 13.315 1.148 19.728.36 1.672-11.772 3.035-11.854 1.251-.351-7.656-1.603-15.422-1.751-25.403-.093-6.221.403-14.976.86-21.198.505-6.865.657-12.313.697-19.19.005-.94 10.54-2.827 10.484-1.903" />
    </G>
  </Svg>
);
export default SvgLowerThighFemurShaft;
