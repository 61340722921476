import { useRef, useEffect } from 'react'
import { StyleSheet, Animated, View, FlatList } from 'react-native'
import { Pressable, Text, Icon } from 'components'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTheme } from '@react-navigation/native'
import { Theme } from 'styles'

const TopTabBar = ({ state, descriptors, navigation, position }) => {
	const { colors } = useTheme()
  const { top } = useSafeAreaInsets()

  const flatList = useRef(null)

  const styles = StyleSheet.create({
    wrap: {
      flexDirection: 'row',
      paddingTop: top,
    },
    tab: {
      paddingLeft: Theme.spacing.thin,
    },
    tabFirst: {
      paddingLeft: Theme.spacing.layout,
    },
    tabLast: {
      paddingRight: Theme.spacing.layout,
    },
    text: {
      color: colors.text,
      fontSize: Theme.font.size.h1,
      fontFamily: Theme.font.family.bold,
    },
  })

  useEffect(function monitorNavigationState() {
    const unsubscribe = navigation.addListener('state', () => {
      flatList.current.scrollToIndex({ index: state.index })
    })

    return unsubscribe
  }, [navigation, state.index])

  const renderItem = ({ item, index }) => {
    const { options } = descriptors[item.key]
    const label = options.title
    const focused = state.index === index

    const handlePress = () => {
      const event = navigation.emit({
        type: 'tabPress',
        target: item.key,
        canPreventDefault: true,
      })

      if (!focused && !event.defaultPrevented) {
        navigation.navigate({ name: item.name, merge: true })
      }
    }

    const inputRange = state.routes.map((_, i) => i)
    const opacity = position.interpolate({
      inputRange,
      outputRange: inputRange.map(i => (i === index ? 1 : 0.3)),
    })

    return (
      <Pressable
        key={item.key}
        onPress={handlePress}
        animated={false}
        style={[
          styles.tab,
          index === 0 && styles.tabFirst,
          index === state.routes.length - 1 && styles.tabLast,
        ]}
      >
        <Animated.Text
          style={[
            styles.text,
            { opacity },
          ]}
        >
          {label}
        </Animated.Text>
      </Pressable>
    )
  }

  return (
    <View style={styles.wrap}>
      <FlatList
        ref={flatList}
        horizontal={true}
        data={state.routes}
        renderItem={renderItem}
        showsHorizontalScrollIndicator={false}
        onScrollToIndexFailed={() => null}
        alwaysBounceHorizontal={false}
      />
    </View>
  )
}

export default TopTabBar