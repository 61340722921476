import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useStores } from 'stores'
import { observer } from 'mobx-react-lite'
import { Text } from 'components'
import { StopLights } from 'components/fracture'
import { Theme } from 'styles'

const Institution = observer(({ institution }) => {
  const { rootStore } = useStores()
  const { appStore } = rootStore

  const content = useMemo(() => {
    if (!appStore.institution) return
    return institution.find((item) => item.name === appStore.institution)?.stoplight
  }, [appStore.institution, institution])

  const styles = StyleSheet.create({
    section: {
      marginTop: Theme.spacing.layout,
    },
  })

  if (!content) return null
  return (
    <View style={styles.section}>
      <Text
        size={'h3'}
        weight={'bold'}
      >
        {appStore.institutionName}
      </Text>
      <StopLights stoplights={content} />
    </View>
  )
})

export default Institution
