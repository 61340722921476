import { useMemo } from 'react'
import { useColorScheme } from 'react-native'
import { Image } from 'components'

const Logo = ({
  style = null,
  width = 200,
  height = 200,
}) => {
  const scheme = useColorScheme()

  const assets = {
    light: require('assets/icon.png'),
    dark: require('assets/icon-dark.png')
  }

  const logo = useMemo(() => {
    if (scheme === 'dark') {
      return assets.dark
    } else {
      return assets.light
    }
  }, [scheme])

	return (
    <Image
      source={logo}
      style={[
        style,
        { width, height }
      ]}
    />
	)
}

export default Logo