import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { api, fetchOptions } from 'utils'
import { flatten, set, groupBy, mapKeys, sortBy, flatMap } from 'lodash'
import Fuse from 'fuse.js'

export default class ContentStore {

  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  loading = true
  error = false
  refreshing = false
  progress = 0
  fractures = []
  splints = []
  taxonomies = {
    extremities: [],
    regions: [],
    bones: [],
  }
  pages = []


  async loadContent(refresh) {

    if (refresh) {
      this.loading = false
      this.refreshing = true
    } else {
      this.loading = true
    }

    let asyncContent = [
      { path: 'fractures', service: 'fractures' },
      { path: 'splints', service: 'splints' },
      { path: 'taxonomies.extremities', service: 'extremities' },
      { path: 'taxonomies.regions', service: 'regions' },
      { path: 'taxonomies.bones', service: 'bones' },
      { path: 'taxonomies.segments', service: 'segments' },
      { path: 'pages', service: 'pages' },
    ]

    try {
      for await (const [index, item] of asyncContent.entries()) {
        let content = await api.get(item.service, fetchOptions)
        runInAction(() => {
          set(this, item.path, content)
          this.progress = (index + 1) / asyncContent.length
        })
      }

      runInAction(() => {
        this.error = false
        this.loading = false
        this.refreshing = false
        this.progress = 0
      })
    }

    catch (error) {
      let errorMessage = error.message
      runInAction(() => {
        this.error = errorMessage
        this.loading = false
        this.refreshing = false
        this.progress = 0
      })
    }
  }

  async clearCache() {
    await api.clearCache()
  }

  get flattenedTaxonomy() {
    return flatten(Object.values(this.taxonomies))
  }

  taxonomyById(id) {
    return this.flattenedTaxonomy.find(item => item.id == id)
  }

  taxonomyBySlug(slug) {
    return this.flattenedTaxonomy.find(item => item.slug === slug)
  }

  fracturesByBone(bone, segment) {
    let fractures = this.fractures.filter(fracture => {
      let boneMatch = fracture.taxonomy.bone.includes(this.taxonomyBySlug(bone).id)

      if (!segment) {
        return boneMatch
      } else {
        return (
          boneMatch &&
          segment && fracture.taxonomy.segment &&
          fracture.taxonomy.segment.includes(this.taxonomyBySlug(segment).id)
        )
      }
    })
    return sortBy(fractures, 'order')
  }

  fractureBySlug(slug) {
    return this.fractures.find(fracture => fracture.slug === slug)
  }

  splintBySlug(slug) {
    return this.splints.find(splint => splint.slug === slug)
  }

  splintById(id) {
    return this.splints.find(splint => splint.id === id)
  }

  pageBySlug(slug) {
    return this.pages.find(page => page.slug === slug)
  }

  childPagesBySlug(slug) {
    let parent = this.pageBySlug(slug)
    if (parent?.id) {
      return this.pages.filter(page => {
        return page.parent === parent.id
      })
    }
  }

  // Create Fuse.js search
  search(data, term, keys) {
    let fuse = new Fuse(data, {
      keys,
      threshold: 0.4,
      includeMatches: true,
    })
    return fuse.search(term)
  }

  // Convert Bones object to SectionList format
  get structuredBones() {
    let grouped = groupBy(this.taxonomies.bones, 'taxonomy.region')

    return Object.keys(grouped).map(id => {
      let title = this.taxonomyById(id).title.full
      let data = sortBy(grouped[id], 'title')
      return { title, data }
    })

  }

  // Convert Fractures object to SectionList format
  get structuredFractures() {
    let grouped = groupBy(this.fractures, 'taxonomy.region')

    return Object.keys(grouped).map(id => {
      let title = this.taxonomyById(id).title.full
      let data = sortBy(grouped[id], 'order')
      return { title, data }
    })

  }

  get sortedSplints() {
    return sortBy(this.splints, 'order')
  }

  // Fuse searching structured SectionList object
  filteredSectionList(data, term) {
    if (term.length === 0) {
      return data
    }

    const searchKeys = ['title.full', 'title.short', 'searchTerms']

    const filteredSections = data.map((section) => {
      // Perform a search on the data array of the current section.
      const result = this.search(section.data, term, searchKeys)

      // Extract the matched data items from the search results.
      const matchedData = result.map(({ item }) => item)

      // Create a new section object with the original section properties and the filtered data array.
      return {
        ...section,
        data: matchedData,
      }
      // Filter out any sections with an empty data array.
    }).filter((section) => section.data.length > 0)

    return filteredSections
  }

  // Fuse search unstructured objects (Fractures, Splints)
  filteredContent(data, term) {
    if (term.length === 0) {
      return data
    }

    const searchKeys = ['title.full', 'title.short', 'searchTerms']
    return this.search(data, term, searchKeys).map(result => result.item)
  }

}