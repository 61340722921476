import { useState, useMemo, useCallback } from 'react'
import { StyleSheet, View, ActivityIndicator } from 'react-native'
import { Image as ExpoImage } from 'expo-image'
import { Icon } from 'components'
import { useStores } from 'stores'

const Image = (props) => {
  const { rootStore } = useStores()
  const { appStore } = rootStore

  const [ loading, setLoading ] = useState(true)
  const [ error, setError ] = useState(false)
  const [ progress, setProgress ] = useState(0)

  const handleError = useMemo(() => setError(true), [])
  const handleLoadStart = useMemo(() => setLoading(true), [])
  const handleLoad = useMemo(() => {
    setLoading(false)
    setError(false)
  }, [])
  const handleLoadEnd = useMemo(() => setLoading(false), [])
  const handleProgress = useCallback(({ progress }) => setProgress(progress), [progress])

  const styles = StyleSheet.create({
    wrap: {
      maxWidth: appStore.isLargeScreen ? 600 : 'auto',
    },
    status: {
      ...StyleSheet.absoluteFill,
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
    },
  })
  
  return (
    <View style={styles.wrap}>
      <View style={styles.status}>
        {error && <Icon name={'alert'} size={'h1'} />}
        {loading && !error && <ActivityIndicator size={'small'} />}
      </View>
      <ExpoImage
        onError={handleError}
        onLoadStart={handleLoadStart}
        onLoad={handleLoad}
        onLoadEnd={handleLoadEnd}
        onProgress={handleProgress}
        {...props}
      />
    </View>
  )
}

export default Image