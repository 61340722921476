import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useTheme, useNavigation, useRoute } from '@react-navigation/native'
import { startCase } from 'lodash'
import { Selector, Error } from 'components'
import { Theme } from 'styles'
import { Model } from 'assets/model'

const Lower = () => {
  const { colors } = useTheme()
  const navigation = useNavigation()
  const route = useRoute()
  const extremity = route.name

  const [ region, setRegion ] = useState(null)
  const model = Model.lower

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      padding: Theme.spacing.layout,
    },
    button: {
      marginTop: Theme.spacing.thin,
    },
  })

  const handlePush = () => {
    navigation.push('region', {
      extremity: extremity,
      region: region
    })
  }

  return (
    <View style={styles.wrap}>
      <Error />
      <Selector
        model={Model.lower}
        current={region}
        setCurrent={setRegion}
        handlePush={handlePush}
      />
    </View>
  )
}

export default Lower