import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootDistalPhalanges = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M50.509 23.146c-.091.487-.743 1.033-.558 1.504.24.613 1.295.545 1.798.513.642-.036 1.588.129 2.114-.314.55-.462.432-1.16-.081-1.58l-.091-.274c-.404-1.216.515-2.667-.676-3.48-.397-.272-1.04-.697-1.543-.583-.472.105-.714.67-1.002 1.019-.464.563-.508.679-.218 1.337.257.585.376 1.209.257 1.858M41.9 13.8c-.09.507-.742 1.07-.558 1.559.243.633 1.295.56 1.799.53.642-.04 1.588.132 2.114-.326.549-.476.432-1.2-.081-1.633l-.091-.283c-.405-1.26.514-2.763-.677-3.603-.397-.282-1.04-.721-1.542-.603-.472.108-.714.693-1.003 1.053-.463.583-.507.703-.217 1.385.256.605.375 1.25.256 1.922M31.356 7.969c-.048.552-.652 1.209-.427 1.72.293.664 1.336.507 1.836.436.636-.092 1.595.022 2.08-.513.509-.556.333-1.323-.215-1.753l-.114-.298c-.506-1.326.288-3.013-.97-3.827-.418-.274-1.095-.7-1.587-.534-.462.155-.655.802-.912 1.212-.416.662-.45.795-.104 1.508.305.633.476 1.317.413 2.049M20.117 5.597c-.006.583-.558 1.313-.295 1.835.344.68 1.374.449 1.867.34.628-.136 1.593-.08 2.037-.671.465-.618.23-1.416-.348-1.833l-.137-.306c-.607-1.361.054-3.19-1.263-3.965-.44-.261-1.147-.666-1.626-.46-.448.193-.591.885-.817 1.336-.364.724-.387.866.012 1.594.353.646.577 1.354.57 2.13M12.342 14.895c1.084.663.456-1.644-.64-4.182-1.094-2.538.549-6.056-.73-7.582C9.696 1.604 6.867-.68 4.767 1.514c-2.1 2.19-1.644 2.554-1.278 4.199.364 1.642 1.917 1.094.73 3.284-1.187 2.193-2.844 2.726-2.83 4.84.015 2.118 1.183 1.047 3.378.637 4.44-.83 5.842-.636 7.576.42" />
    </G>
  </Svg>
);
export default SvgLowerFootDistalPhalanges;
