import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootLateralCuneiform = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M28.835 91.298c.63 2.585 2.25 6.413 4.494 7.733.316.184 1.109.097 1.46 0 3.933-1.095 3.384-8.49 6.064-10.95 2.004-1.84.738-2.985-1.95-4.385-2.24-1.167-5.14-2.587-7.075-.747-1.71 1.622-3.575 5.962-2.993 8.349" />
  </Svg>
);
export default SvgLowerFootLateralCuneiform;
