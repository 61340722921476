import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerThighFemurProximal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 204"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M11 53h41v48H11z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
      <Path d="M14.87 55.03c8.006-4.795 11.517 1.939 14.619 4.06l.349.369c2.636.277 5.517.433 7.998 1.504 5.287 2.277 3.59 7.688 1.578 12.022-2.678 5.766-6.34 11.018-8.118 17.225-.589 2.053-.954 4.153-1.185 6.275-.1.918-.174 1.84-.23 2.764-.182 2.973-10.423.052-10.337-.84.104-1.074.252-2.12.412-3.152.552-3.57 1.238-6.981.672-10.87-.835-5.75-2.274-12.384-8.347-14.964-3.231-1.376-1.485-11.95 2.59-14.392" />
    </G>
  </Svg>
);
export default SvgLowerThighFemurProximal;
