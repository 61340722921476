import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgUpperArmFull = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85 129"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M23.647 14.74c-1.435-.386-4.256-.31-4.74 2.076-.483 2.383-1.425 8.948-.706 14.135.717 5.189-2.811 48.899-4.8 58.7-1.986 9.802-2.875 18.336-6.175 22.153-3.297 3.812-8.104 5.364-7.087 8.657 1.014 3.294 4.21 6.916 7.655 5.847 3.445-1.07 1.885-4.233 5.793-.195 3.909 4.04 4.927 2.845 7.04-.374 2.109-3.222 3.304 1.96 4.085-1.894.78-3.856-4.404-7.344-4.705-12.783s6.24-73.696 9.329-77.852 4.523-2.818 6.453-5.521c2.744-3.851 2.515-8.95.66-11.57-1.005-1.42-5.187-4.868-8.721-4.047-3.534.823-4.081 2.668-4.081 2.668" />
      <Path d="M73.303 20.01c-.29-3.172-2.31-7.79-6.06-11.828-3.75-4.04-10.674.577-12.982 1.987-1.503.917-1.907 2.057-2.244 2.545-.171.248-.675 1.67-.865 2.327-.348 1.31-1.306 2.988-2.865 4.067-.763.545-1.674.932-2.58 1.032-.451.036-.894.029-1.3-.056-.21-.032-.404-.096-.572-.141-.188-.072-.378-.133-.533-.212-.325-.143-.591-.324-.826-.476a5 5 0 0 1-.576-.434c-.307-.253-.464-.418-.464-.418l.047-.072.573.23c.365.138.868.37 1.483.524 1.19.326 2.898.295 4.37-.561 1.474-.855 2.488-2.37 2.955-3.58.244-.61 1.444-3.465-.5-6.041-3.208-4.255-7.878.608-9.772 1.564-.187.095-.036 3.486-4.22 2.764-.828-.143 1.822 1.695 3.264 4.725s1.567 8.105-2.705 11.522c-2.975 2.38.163 1.497 4.202 4.526 4.04 3.03 13.417 34.623 19.33 35.344 5.915.722 9.81-11.541 10.532-16.59s2.597-29.573 2.308-32.747" />
      <Path d="M35.036 9.277c-1.818-.526-.743-1.368-.355-2.652.48-1.597-.667-2.725-2.252-2.782-1.62-.06-2.994 1.026-4.335 1.778-1.301.73-7.6 3.384-5.666 5.686.965 1.149 3.342.065 4.435-.285 1.59-.51 3.146-.66 4.78-.238 3.016.776 6.513 4.008 8.625-.15 0 0-3.67-.905-5.232-1.357" />
      <Path d="M33.92 2.994c.722.283 1.94 3.602 1.836 4.323-.105.72 3.068-1.62 8.118-1.443 5.049.176 17.166-.177 21.35.689S75.468 8.66 79.363 8.277s5.049-2.863 5.482-4.437c.433-1.573 1.299-4.747-.865-3.592s-5.855 4.225-10.646 3.916c-4.791-.31-12.621-1.67-18.79-2.39-8.125-.95-20.623 1.22-20.623 1.22" />
    </G>
  </Svg>
);
export default SvgUpperArmFull;
