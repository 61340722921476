import APIpeline from 'apipeline'
import Constants from 'expo-constants'
import AsyncStorage from '@react-native-async-storage/async-storage'
import fetch from 'cross-fetch'

const { environment } = Constants.expoConfig.extra

const boundFetch = fetch.bind(window)

const DebugAPIOptions = {
  printNetworkRequests: true,
  debugAPI: false,
  cacheExpiration: 1000 * 30,
}

const APIOptions = {
  fetchMethod: boundFetch,
  domains: { default: environment.domain },
  prefixes: { default: environment.api },
  cacheExpiration: 1000 * 60 * 60 * 24 * 30,
  ...(__DEV__ && DebugAPIOptions),
}

const APIServices = {
  fractures: {
    path: 'fracture',
    responseMiddleware: (data) => transformFractures(data),
  },
  splints: {
    path: 'splint',
    responseMiddleware: (data) => transformSplints(data),
  },
  extremities: {
    path: 'extremity',
    responseMiddleware: (data) => transformTaxonomy(data),
  },
  regions: {
    path: 'region',
    responseMiddleware: (data) => transformTaxonomy(data),
  },
  bones: {
    path: 'bone',
    responseMiddleware: (data) => transformTaxonomy(data),
  },
  segments: {
    path: 'segment',
    responseMiddleware: (data) => transformTaxonomy(data),
  },
  pages: {
    path: 'pages',
    responseMiddleware: (data) => transformPages(data),
  },
}

const fetchOptions = {
  queryParameters: {
    per_page: 500,
    acf_format: 'standard',
  },
  headers: {
    'Cache-Control': 'no-cache',
  },
}

const transformFractures = (data) => {
  let newData = []
  data.map((item) => {
    let revisedItem = {
      id: item.id,
      slug: item.slug,
      title: {
        full: item.title.rendered,
        short: item.acf.short_title,
      },
      content: item.content.rendered,
      institution: item.acf.institution,
      modified: item.modified,
      taxonomy: item.acf.taxonomy,
      splints: item.acf.splints,
      searchTerms: item.acf.search_terms && item.acf.search_terms.map((term) => term.slug),
      image: item.acf.image,
      alert: item.acf.alert,
      followup: item.acf.followup,
      gallery: item.acf.gallery,
      references: item.acf.references,
      order: item.acf.menu_order,
    }

    newData.push(revisedItem)
  })

  return newData
}

const transformSplints = (data) => {
  let newData = []

  data.map((item) => {
    let revisedItem = {
      id: item.id,
      slug: item.slug,
      title: { full: item.title.rendered },
      content: item.content.rendered,
      modified: item.modified,
      image: item.acf.image,
      basics: item.acf.basics,
      order: item.acf.menu_order,
    }
    newData.push(revisedItem)
  })

  return newData
}

const transformTaxonomy = (data) => {
  let newData = []
  data.map((item) => {
    let revisedItem = {
      id: item.id,
      slug: item.slug,
      title: { full: item.name },
      taxonomy: item.acf.taxonomy,
    }
    newData.push(revisedItem)
  })

  return newData
}

const transformPages = (data) => {
  let revisedData = []

  data.map((item) => {
    let revisedItem = {
      id: item.id,
      slug: item.slug,
      title: { full: item.title.rendered },
      content: item.content.rendered,
      parent: item.parent,
      order: item.menu_order,
    }
    revisedData.push(revisedItem)
  })

  return revisedData
}

const api = new APIpeline(APIOptions, APIServices, AsyncStorage)

export { api, fetchOptions }
