import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootCalcaneus = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M24.75 151.976c1.283 4.468 5.34 6.513 9.786 6.504 4.69-.008 8.074-2.21 10.789-5.87l-.244-2.19a45 45 0 0 1-.028-2.796c.126-4.27 1.707-8.2 2.474-12.356.745-4.025.592-8.143 1.3-12.181.553-3.152 2.588-9.413-.298-12.023-3.291-2.977-4.752 3.586-4.774 5.636-.04 3.947 1.17 7.77.178 11.69-.833 3.297-2.553 7.116-5.412 9.12-3.246 2.275-7.578 1.7-10.874 3.812-3.453 2.21-3.947 6.999-2.896 10.654" />
  </Svg>
);
export default SvgLowerFootCalcaneus;
