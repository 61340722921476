import { useWindowDimensions, View } from 'react-native'
import { useTheme } from '@react-navigation/native'
import ProgressBar from 'react-native-progress/Bar'
import { Theme } from 'styles'

const Progress = ({
  progress = 0,
  style = null,
  margin = 0,
}) => {

  const { colors } = useTheme()
  const { width } = useWindowDimensions()

  if (progress === 1) return

	return (
    <View style={style}>
      <ProgressBar
        animated={true}
        useNativeDriver={true}
        progress={progress}
        color={colors.primary}
        borderWidth={0}
        borderRadius={0}
        width={width - (margin*2)}
      />
    </View>
	)
}

export default Progress