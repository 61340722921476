import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgUpperFull = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 108 268"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M45.761 14.512c-1.435-.386-4.256-.309-4.739 2.076-.484 2.383-1.426 8.948-.707 14.135.717 5.19-2.81 48.9-4.799 58.701-1.987 9.801-2.876 18.335-6.176 22.152-3.297 3.812-8.104 5.364-7.087 8.657 1.014 3.294 4.21 6.916 7.655 5.847 3.445-1.07 1.885-4.233 5.793-.195 3.91 4.04 4.927 2.845 7.04-.374 2.11-3.222 3.304 1.96 4.085-1.894.781-3.856-4.404-7.344-4.705-12.783-.3-5.438 6.241-73.696 9.33-77.852 3.088-4.156 4.522-2.818 6.452-5.52 2.744-3.852 2.515-8.95.661-11.57-1.006-1.42-5.188-4.869-8.722-4.048-3.534.823-4.08 2.668-4.08 2.668" />
      <Path d="M95.418 19.783c-.29-3.173-2.31-7.79-6.06-11.83-3.75-4.038-10.674.578-12.982 1.988-1.503.917-1.907 2.057-2.244 2.545-.171.248-.675 1.67-.865 2.327-.348 1.31-1.306 2.988-2.865 4.067-.763.545-1.674.932-2.58 1.032-.451.036-.894.029-1.3-.056-.21-.032-.404-.096-.572-.141-.188-.072-.378-.133-.533-.212-.325-.143-.591-.324-.826-.476a5 5 0 0 1-.576-.434c-.307-.253-.464-.418-.464-.418l.047-.072.573.23c.365.138.868.37 1.483.524 1.19.326 2.898.295 4.37-.561 1.474-.855 2.488-2.37 2.955-3.58.244-.61 1.444-3.465-.5-6.041-3.208-4.255-7.878.608-9.772 1.564-.187.095-.036 3.486-4.22 2.764-.828-.143 1.822 1.695 3.264 4.725s1.567 8.105-2.705 11.522c-2.975 2.38.163 1.497 4.202 4.526 4.04 3.03 13.417 34.623 19.33 35.344 5.915.722 9.81-11.541 10.532-16.59s2.597-29.573 2.308-32.747" />
      <Path d="M57.15 9.049c-1.817-.526-.742-1.368-.354-2.652.48-1.597-.667-2.725-2.252-2.782-1.62-.06-2.994 1.026-4.335 1.778-1.301.73-7.6 3.384-5.666 5.686.965 1.149 3.342.065 4.435-.285 1.59-.51 3.146-.66 4.78-.238 3.016.776 6.513 4.008 8.625-.15 0 0-3.67-.905-5.232-1.357" />
      <Path d="M56.035 2.766c.722.283 1.94 3.602 1.836 4.323-.105.72 3.068-1.62 8.118-1.443 5.049.176 17.166-.177 21.35.689s10.243 2.097 14.138 1.714 5.049-2.863 5.482-4.437c.433-1.573 1.299-4.747-.865-3.592s-5.855 4.225-10.646 3.916c-4.791-.31-12.621-1.67-18.79-2.39-8.125-.95-20.623 1.22-20.623 1.22M27.242 127.734c-3.029-.52-3.418.896-1.667 3.342 1.826 2.552 1.163 8.73-1.44 19.123-3.306 13.203-3.4 28.885-3.71 36.974-.31 8.091-2.228 15.005-2.58 18.382-.354 3.376-1.88 7.41 3.34 5.041 6.016-2.73 8.666 1.151 7.681-3.268-1.317-5.91-3.276-36.367-1.537-44.79 1.74-8.426 3.414-16.696 4.234-20.968.823-4.269-1.192-8.312.237-10.086 1.43-1.77.881-5.384-1.286-4.184-2.229 1.232-2.459.575-3.272.434" />
      <Path d="M38.256 129.305c2.766.773 5.704-2.496 5.794.27.09 2.761-1.935 2.598-2.68 10.623-.745 8.023-2.188 24.223-1.935 30.295.252 6.072-2.035 19.141-2.944 24.811-.91 5.671-1.753 6.872-1.688 9.451.042 1.61.32 6.742-1.797 6.067s-4.97-1.906-3.306-7.122c1.665-5.217 2.826-15.777 3.3-20.558.472-4.78-.279-20.342-.678-25.43-.398-5.09-1.397-12.587-.268-14.542 1.667-2.887-.69-9.902-.228-10.519 2.353-3.16-.303-3.485 2.591-4.573 2.575-.966 2.684.902 3.84 1.227M3.876 239.58c-.479-.177-1.072-.25-1.383-.765-.272-.446-.87-.302-1.05.28-.165.536-.05 1.103-.1 1.652-.05.536-.287.996-.592 1.433-.3.431-.63.866-.566 1.426.053.472.422.851.88.546.37-.242.591-.71.805-1.08.273-.478.477-.999.768-1.463.29-.456.72-.54 1.142-.82.452-.304.77-.963.096-1.21M7.495 229.217a5.8 5.8 0 0 1-1.397-.589c-.22-.124-.644-.478-.924-.36-.29.12-.32.707-.369.96a62 62 0 0 1-.379 1.857A56 56 0 0 0 4 233.211c-.118.64-.223 1.303-.49 1.9-.343.761-1.416 1.313-1.587 2.15-.037.193 0 .72.152.846.858.708 1.218.887 2.034 1.148.293.093.727.21.987-.025.28-.25.224-.769.214-1.103-.03-1.119.074-2.018.614-3.014.495-.912.961-1.935 1.811-2.568.677-.505 1.883-.909 1.765-1.957-.112-1.026-1.206-1.16-2.005-1.37" />
      <Path d="M14.963 219.126c-.356-.433-.705-.878-1.065-1.304-.63-.747-1.405-1.17-2.113-.246-.744.97-.952 2.28-1.556 3.336-.614 1.078-1.33 2.224-2.22 3.093-.75.731-1.876 1.117-2.36 2.09-.103.206-.22.417-.254.643-.22 1.53 1.18 2.043 2.147 2.418.853.33 2.347.603 2.821-.437.236-.515.204-1.142.281-1.692.094-.665.3-1.29.595-1.892.568-1.142 1.296-2.478 2.365-3.225.532-.373 2.262-.698 2.143-1.55-.066-.458-.508-.895-.784-1.234M17.07 262.293c.21-.33.818-1.087.495-1.428-.32-.337-.875.195-1.473.296-.598.11-1.236-.215-1.424.09-.455.742.87 1.382.843 2.256-.02.783.02 2.223 1.195 2.1.448-.046.86-.443 1-.858.19-.543-.106-1.007-.417-1.426-.265-.36-.489-.613-.22-1.03M14.65 258.756c.01.595-.094 1.56.561 1.86.615.283 1.695.08 2.185-.372.602-.558.163-1.284-.139-1.87-.373-.712-.496-1.423-.41-2.22.102-.93 1.312-3.145.18-3.802-.59-.343-1.642-.013-2.237.172-.73.23-1.197.661-.966 1.465.228.79.817 1.408.994 2.224.165.757.084 1.53-.1 2.274" />
      <Path d="M15.318 240.716c-.876-.193-1.919-.366-1.94.83-.019 1.102.461 2.224.778 3.263.312 1.023.536 2.055.447 3.132-.048.6-.188 1.184-.36 1.757-.134.454-.345.947-.332 1.428.028 1.038 1.35 1.177 2.144 1.09 1.02-.118 1.908-.704 1.833-1.818-.073-1.084-.652-2.1-.857-3.164-.197-1.014-.129-2.008.302-2.953.395-.869 1.012-1.73.97-2.733-.05-1.11-.972-1.33-1.907-1.108-.276.063-.549.154-.822.241" />
      <Path d="M18.942 220.62c-1.6-.133-2.842.144-2.964 1.59-.036.43.205 1.356.295 1.701.411 1.568.2 3.096.036 4.684-.167 1.602.002 3.26-.297 4.847-.31 1.642-1.429 2.907-1.884 4.481-.464 1.6.912 2.363 2.338 2.406 1.43.038 2.868-.6 2.861-2.2 0-.815-.24-1.628-.408-2.42-.206-.98-.365-1.964-.407-2.967-.092-2.059.234-4.16 1.058-6.059.356-.822.534-1.6.982-2.376.342-.598.72-1.166.764-1.87.094-1.485-1.104-1.714-2.374-1.818M25.624 263.647c.202-.448.79-.866.43-1.4-.393-.582-1.148-.34-1.635-.056l-.357.048c-.426.03-1.256.05-1.404.573-.134.48.498.87.714 1.224.323.533.536 1.01.57 1.598.03.581-.096 1.196.238 1.66.293.411.681.813 1.23.68.545-.133.884-.695.865-1.232-.039-1.03-1.125-2.038-.65-3.095M31.506 261.345c.191-.407.735-.792.403-1.273-.362-.527-1.062-.304-1.515-.044l-.333.044c-.39.027-1.162.05-1.3.524-.125.433.457.784.656 1.105.301.485.495.918.525 1.45.027.526-.09 1.082.218 1.503.27.368.627.735 1.138.611.503-.118.821-.63.805-1.118-.033-.932-1.04-1.845-.597-2.802M38.51 251.565c.207-.338.745-.633.472-1.07-.299-.474-.97-.326-1.413-.129l-.31.016c-.369-.002-1.09-.026-1.252.371-.15.368.365.704.528.992.244.432.394.818.381 1.28-.014.45-.165.921.09 1.301.223.334.532.671 1.014.597a1 1 0 0 0 .835-.914c.04-.804-.828-1.647-.345-2.444M22.313 260.44c.124.885.346 1.205 1.264 1.082.54-.07 1.828.005 2.1-.586.294-.639-.222-1.793-.35-2.438-.165-.818-.42-1.66-.485-2.486-.067-.825.49-1.34.635-2.104.161-.845-.466-1.389-1.28-1.237-.39.075-.755.25-1.137.35-.386.1-.792.117-1.177.222-1.025.278-.668 1.195-.19 1.856.607.84 1.261 1.36 1.263 2.464.001.922-.128 1.832-.557 2.656a.13.13 0 0 1-.024-.098M25.238 242.463c.171-.9.111-1.754-.918-1.988-.863-.196-2.303-.218-3.079.277-.906.577-.258 1.953.02 2.711.37 1.026.622 1.963.592 3.057-.029 1.062-.062 2.117-.02 3.177.008.231-.16.841-.16.841.017.813-.055 2.026 1.007 2.128.812.076 2.191-.253 2.656-.989.496-.789-.21-1.945-.435-2.72-.304-1.048-.343-2.138-.263-3.219.08-1.12.394-2.178.6-3.275" />
      <Path d="M21.905 223.346c.82-.21 2.601-.868 3.23.048.293.425.172 1.04.072 1.506-.17.785-.445 1.548-.653 2.322-.873 3.258-.522 6.455.637 9.581.387 1.043.75 2.311-.402 3.01-1.027.62-2.887.583-3.62-.497-.709-1.05.04-2.55.277-3.64.286-1.325.262-2.702.363-4.05.11-1.476.13-2.859-.36-4.279-.379-1.093-.952-2.163-.289-3.267.135-.223.296-.429.455-.634a.3.3 0 0 1 .143-.028M29.847 231.031c-.006-.057-.012-.035-.018.038.045.282.062.332.018-.038" />
      <Path d="M30.473 234.572c-.632-1.069-.67-3.116-.644-3.503-.122-.768-.467-3.294-.497-4.297-.037-1.225.645-2.799.174-3.969-.454-1.126-2.542-1.18-3.495-.512-1.173.822-.128 1.987.312 2.965.195.434.331 1.593.403 1.862.683 2.578.6 4.562.294 7.183-.144 1.218-.217 2.583.843 3.435.874.703 2.666.986 3.434-.047.737-.989-.328-2.279-.824-3.117M31.743 240.248c.173-1.074-.469-1.388-1.456-1.337-.279.013-.84.207-1.13.222-.788.053-1.959-.253-2.217.743-.282 1.09.517 2.344 1.004 3.258.553 1.04.954 2.006.724 3.2-.208 1.072-.86 2.197-.706 3.312.131.942 1.135.986 2.098.971.962-.014 1.885-.084 2.16-.884.344-.995-.31-1.606-.64-2.544-.356-1.02-.62-2.642-.583-3.744.041-1.115.577-2.11.746-3.197M31.844 252.305c-.003-.864-.347-1.264-1.098-1.11-.88.18-2.003-.087-2.554.245-.87.522-.166 1.298.311 2.09.55.913.554 1.895.446 2.928-.08.778-.44 1.886-.16 2.65.261.725 1.392.561 2.004.614.544.045 1.445.355 1.532-.414.091-.802-.748-1.682-.779-2.489-.03-.826-.263-1.532-.314-2.354-.048-.766.616-1.413.612-2.16M36.138 231.836a9.9 9.9 0 0 1-1.493-3.379c-.316-1.335-.675-2.702-.756-4.074-.04-.625.011-1.242.046-1.864.026-.459.072-.998-.195-1.402-.644-.96-2.438-.533-3.249-.072-1.306.745-.42 1.683.202 2.641 1.138 1.762 2.403 5.595 2.154 8.114-.12 1.21-.282 2.615.708 3.53.895.827 2.56.85 3.304-.203.765-1.087-.098-2.375-.72-3.29" />
      <Path d="M34.61 236.328c-.828-.127-1.452.058-1.105 1.017.281.79.906 1.41 1.274 2.156.345.695.727 1.55.66 2.342-.056.676-.73 1.515-.516 2.187.215.664 1.364.447 1.875.393.676-.066 1.491-.205 1.488-1.047-.005-.81-.578-1.552-.789-2.315a14.4 14.4 0 0 1-.43-2.374c-.047-.476.001-.935.076-1.403.056-.34.194-.814-.014-1.128-.45-.686-1.678-.066-2.204.184M38.358 248.284c-.327-.414-.629-.767-.65-1.322-.023-.53.331-1.01.218-1.538-.109-.52-.552-.942-1.034-.554l-.389.208c-.385-.07-1.175-.264-1.496.057-.38.38.297 1.011.494 1.33.277.454.348.961.407 1.483.053.472-.04 1.016.097 1.474.162.532.606.586 1.094.496.411-.079 1.153-.085 1.49-.346.452-.35.03-.956-.23-1.288M26.296 217.25c-.247-1.153-.107-3.208-1.446-3.69-.758-.273-1.843-.032-2.423.514-.655.612-.526 1.434-.315 2.22 0 0 .104 1.586.042 2.35-.053.646-.28 1.397-.002 2.021.581 1.29 2.797 1.275 3.771.498 1.203-.959.645-2.656.373-3.913" />
      <Path d="M29.155 214.983c-.89-1.412.962-3.375-.794-4.443-.647-.39-1.746-.627-2.44-.24-.766.428-.75 1.385-.679 2.143.01.104.004.321.055.411.82 1.435 1.681 3.418 1.81 4.991.038.46-.06 2.315.667 2.39.812.085.485-1.68.533-2.1.121-1.045.917-.75 1.507-1.377.643-.68-.298-1.203-.659-1.775" />
      <Path d="M32.28 217.22c-.879-1.35-3.594-.065-3.638 1.224-.006.178.098.534.096.715-.025 1.21.603 1.585 1.647 1.314.58-.15 1.987-.216 2.295-.772.265-.483-.11-2.033-.4-2.482M33.391 213.931c-.324-1.108-.253-2.037-1.296-2.703-.437-.28-.87-.64-1.423-.578-.718.08-.728.446-.419 1.128.107.236.116.79.034 1.036-.326.98-.965 1.418-.364 2.38.524.845 1.497 1.45 2.524 1.272 1.035-.178 1.196-1.665.944-2.535M19.653 215.88c-.812-.493-2.226-1.29-2.134.298l.171.497c.092.958-.445 2.067.236 2.87.533.632 1.568 1.103 2.314.601.674-.454 1.297-1.653 1.221-2.457-.092-.994-1.054-1.35-1.808-1.81" />
      <Path d="M19.698 211.546c-.825.604-2.27 1.461-2.185 2.24.108.987 1.835 2.127 2.767 1.382.187-.15.207-.47.15-.72 1.254.382 1.178-.823 2.084-1.279.958-.483 1.933.001 1.943-1.47.016-2.392-3.665-.958-4.759-.153M16.645 215.816c-.15-.756-.033-1.936-1.123-1.318-.559.318-1.449.901-1.562 1.53-.023.126.055.383.068.51.051.5.996 1.108 1.34 1.5.426.484 1.466 1.16 1.76.153.18-.626-.357-1.738-.483-2.375" />
    </G>
  </Svg>
);
export default SvgUpperFull;
