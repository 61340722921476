import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandPisiform = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M11.46 116.434c.424-.915 1.933-1.524 2.522-.937.59.588 1.193 1.191 1.848 1.191.654 0 2.05-.03 2.583.987.408.777.87 2.64 0 3.587-.666.724-2.066 1.563-3.567 1.472-.903-.055-1.546-.208-2.515-1.014s-1.297-4.37-.872-5.286" />
  </Svg>
);
export default SvgHandPisiform;
