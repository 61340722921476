import { StyleSheet, View } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Pressable, Icon, Text } from 'components'
import { Theme } from 'styles'

const Sidebar = ({ state, descriptors, navigation }) => {

  const { colors } = useTheme()
  const { top } = useSafeAreaInsets()

  const icons = {
    extremityStack: 'human',
    listStack: 'sort-alphabetical-ascending',
    aboutStack: 'information-outline',
    debugStack: 'bug',
  }

  const styles = StyleSheet.create({
    wrap: {
      flex: 1,
      backgroundColor: colors.background,
      paddingTop: top + Theme.spacing.thin,
      paddingLeft: Theme.spacing.thin,
    },
    button: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: Theme.spacing.layout,
    },
    buttonActive: {
      backgroundColor: colors.card,
      borderRadius: Theme.spacing.border,
    },
    text: {},
    icon: {
      paddingRight: Theme.spacing.border,
      color: colors.text,
    },
  })

  return (
    <View style={styles.wrap}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key]
        const label = options.title !== undefined
            ? options.title
            : route.name

        const focused = state.index === index

        const handlePress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          })

          if (!focused && !event.defaultPrevented) {
            navigation.navigate({ name: route.name, merge: true })
          }
        }

        return (
          <Pressable
            key={route.key}
            onPress={handlePress}
            animated={false}
            style={[
              styles.button,
              focused && styles.buttonActive,
            ]}
          >
            <Icon
              name={icons[route.name]}
              style={styles.icon}
              size={'h2'}
            />
            <Text style={styles.text}>{label}</Text>
          </Pressable>
        )
      })}
    </View>
  )
}

export default Sidebar