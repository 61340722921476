import { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { useRoute } from '@react-navigation/native'
import { Settings } from 'screens'
import { TabView, Empty, Error } from 'components'
import { HTMLRenderer } from 'components/HTMLRenderer'
import { sortBy } from 'lodash'
import { Theme } from 'styles'


const About = observer(() => {
  const { rootStore } = useStores()
  const { contentStore } = rootStore
  const route = useRoute()

  const parent = route.name.toLowerCase()
  const pages = contentStore.childPagesBySlug(parent)

	const routes = useMemo(() => {
    let routesMap = []
    if (pages) {
  		pages.map((page) => {
  			routesMap.push({
  				key: page.slug,
  				title: page.title.full,
          order: page.order,
          content: <HTMLRenderer html={page.content} />,
  			})
  		})
    }
		return sortBy(routesMap, 'order')
	}, [pages])

  if (contentStore.error) {
    return (
      <Error />
    )
  } else {
    return (
      <TabView routes={routes} />
    )
  }

})

export default About