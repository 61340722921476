import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandCapitate = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M28.895 116.215c.554 2.644.209 7.35 3.274 8.468 1.732.63 4.219.09 5.556-1.155 1.503-1.394 1.219-3.279.742-5.083 0 0-.221-3.63-.073-5.378.127-1.48.657-3.199.024-4.63-1.317-2.958-6.392-2.948-8.63-1.18-2.765 2.184-1.506 6.078-.893 8.958" />
  </Svg>
);
export default SvgHandCapitate;
