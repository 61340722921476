import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandTrapezoid = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M44.092 119.424c1.855 1.133 5.089 2.974 4.892-.665l-.386-1.14c-.204-2.194 1.04-4.73-.514-6.576-1.214-1.453-3.58-2.542-5.294-1.399-1.55 1.032-2.982 3.772-2.82 5.613.203 2.28 2.4 3.105 4.122 4.167" />
  </Svg>
);
export default SvgHandTrapezoid;
