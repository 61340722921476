import { useMemo } from 'react'
import { PixelRatio } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Theme } from 'styles'

const Icon = ({
  name = null,
  size = 'p',
  color = 'text',
  themed = true,
  ...props
}) => {
  const { colors } = useTheme()
  const fontScale = PixelRatio.getFontScale()

  const handleSize = useMemo(() => {
    switch (typeof size) {
      case 'string':
        return Theme.font.size[size]
        break
      case 'number':
        return size
        break
      default:
        return Theme.font.size.p
    }
  }, [size])

  return (
    <MaterialCommunityIcons
      name={name}
      size={handleSize * fontScale}
      color={themed ? colors[color] : color}
      {...props}
    />
  )
}

export default Icon
