import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandHamate = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M17.115 116.122c1.376.321 1.6 1.425 2.459 1.162 2.02-.62 3.897-2.217 3.96-3.826.015-.407-.22-1.224-.215-1.638.069-2.772-1.366-3.635-3.756-3.027-1.331.34-4.556.476-5.263 1.746-.613 1.103-.261 3.75.4 4.78.66 1.03 1.04.482 2.415.803" />
  </Svg>
);
export default SvgHandHamate;
