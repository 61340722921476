import { useState, useEffect, useMemo } from 'react'
import { StyleSheet, View, Image as RNImage } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useInternalRenderer } from 'react-native-render-html'
import { useTheme } from '@react-navigation/native'
import { StatusBar } from 'expo-status-bar'
import ImageView from 'react-native-image-viewing'
import { Pressable, Image } from 'components'
import { Theme } from 'styles'

const ImageRenderer = (props) => {

	const { colors } = useTheme()
  const { top, bottom } = useSafeAreaInsets()

	const { rendererProps } = useInternalRenderer('img', props)
	const { source, alt, width, height } = rendererProps
	const classes = props.tnode.classes

  const [ imageWidth, setImageWidth ] = useState(width || 1)
  const [ imageHeight, setImageHeight ] = useState(height || 1)
  const [ modalVisible, setModalVisible ] = useState(false)

  useEffect(function getImageDimensions() {
    RNImage.getSize(source.uri, (width, height) => {
      setImageWidth(width)
      setImageHeight(height)
    })
  }, [source])

  const styles = StyleSheet.create({
    wrap: {},
    image: {
			aspectRatio: imageWidth / imageHeight,
      width: '100%',
    },
    caption: {
      flex: 1,
      padding: Theme.spacing.layout,
      paddingBottom: Theme.spacing.layout + bottom,
      backgroundColor: Theme.color.light.colors.text + Theme.opacity.opaque,
    },
    captionText: {
      color: Theme.color.light.colors.background,
    }
  })

  const statusBarStyle = useMemo(() => {
    return modalVisible ? 'light' : 'auto'
  }, [modalVisible])

  return (
    <View style={styles.wrap}>
      <StatusBar style={statusBarStyle} animated={true} />
      <Pressable disabled={classes.includes('no-lightbox')} onPress={() => setModalVisible(true)}>
        <Image
          source={source}
          style={styles.image}
          alt={alt}
        />
      </Pressable>
      <ImageView
        images={[source]}
        imageIndex={0}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      />
    </View>
  )
}

export default ImageRenderer