import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandThumbMetacarpal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M54.866 112.034c.814.993 1.607 2.014 2.427 2.996 1.435 1.717 3.203 2.694 4.833.584 1.716-2.213 2.205-5.21 3.602-7.626 1.415-2.46 3.066-5.078 5.116-7.06 1.72-1.667 4.303-2.54 5.424-4.763.234-.47.51-.953.587-1.47.52-3.5-2.684-4.69-4.894-5.559-1.947-.766-5.37-1.404-6.467.973-.541 1.176-.475 2.613-.657 3.872a14.3 14.3 0 0 1-1.383 4.326c-1.312 2.612-2.992 5.663-5.446 7.362-1.224.851-5.187 1.578-4.924 3.532.145 1.048 1.154 2.053 1.782 2.833" />
  </Svg>
);
export default SvgHandThumbMetacarpal;
