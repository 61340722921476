import { useState } from 'react'
import { useTheme, useNavigation } from '@react-navigation/native'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { FlatList } from 'react-native'
import { Error } from 'components'
import { Item, Search, listViewProps } from 'components/list'
import { Theme } from 'styles'


const Splints = observer(() => {
	const { colors } = useTheme()
  const navigation = useNavigation()

  const { rootStore } = useStores()
  const { contentStore } = rootStore

  const [ search, setSearch ] = useState('')

  const handlePush = (splint) => {
    navigation.push('splint', {
      splint: splint,
    })
  }

  if (contentStore.error) {
    return (
      <Error />
    )
  } else {
    return (
      <FlatList
        data={contentStore.filteredContent(contentStore.sortedSplints, search)}
        refreshing={contentStore.refreshing}
        onRefresh={() => contentStore.loadContent(true)}
        renderItem={({ item }) => <Item title={item.title.full} onPress={() => handlePush(item.slug)} />}
        ListHeaderComponent={<Search search={search} setSearch={setSearch} />}
        extraData={search}
        {...listViewProps}
      />
    )
  }
})

export default Splints