import { StyleSheet, View } from 'react-native'
import { useTheme } from '@react-navigation/native'
import { Pressable, Icon, Text } from 'components'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { Theme } from 'styles'

const Error = observer(({
  title = null,
  description = null,
  onPress = () => null,
  ...props
}) => {
  const { rootStore } = useStores()
  const { contentStore } = rootStore

  const { colors } = useTheme()

  const handleReload = () => {
    contentStore.loadContent()
  }

  const styles = StyleSheet.create({
    wrap: {
      ...StyleSheet.absoluteFill,
      backgroundColor: colors.background + Theme.opacity.opaque,
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1,
    },
    description: {
      width: '60%',
    },
    text: {
      textAlign: 'center',
    }
  })

  if (contentStore.error) {
  	return (
  		<Pressable style={styles.wrap} onPress={handleReload}>
        <Icon name={'alert'} size={'h1'} />
        <View style={styles.description}>
          <Text style={styles.text} weight={'bold'}>Tap to Retry</Text>
          <Text style={styles.text} size={'sm'}>{contentStore.error}</Text>
        </View>
      </Pressable>
  	)
  }
})

export default Error