import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandThumbProximalPhalanx = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M72.07 88.996c1.136.304 2.18.78 3.193 1.362.508.288 1.47 1.102 2.113.837.665-.274.742-1.619.854-2.197.278-1.423.567-2.835.888-4.249.365-1.614.69-3.236.997-4.866.273-1.464.522-2.98 1.144-4.343.79-1.743 3.253-2.995 3.65-4.91.089-.443.01-1.649-.338-1.939-1.957-1.63-2.78-2.046-4.646-2.649-.67-.216-1.663-.488-2.26.046-.644.572-.52 1.758-.504 2.525.06 2.563-.186 4.621-1.434 6.895-1.143 2.084-2.22 4.422-4.175 5.864-1.556 1.151-4.317 2.062-4.061 4.465.25 2.351 2.752 2.67 4.58 3.16" />
  </Svg>
);
export default SvgHandThumbProximalPhalanx;
