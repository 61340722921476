import { makeAutoObservable, autorun } from 'mobx'
import { makePersistable, PersistStoreMap, stopPersisting } from 'mobx-persist-store'
import * as Device from 'expo-device'
import { routeMap } from 'screens'

export default class AppStore {
  constructor(rootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
    autorun(() => {
      this.init()
    })
  }

  firstLaunch = true
  deviceType = Device.DeviceType.PHONE
  debug = false
  initialRoute = 0
  institutions = [
    { name: 'Denver Health', value: 'denver-health' },
  ]
  institution = null

  persistStore(store) {
    makePersistable(this, {
      name: 'AppStore',
      properties: ['firstLaunch', 'debug', 'initialRoute', 'institution'],
    })
  }

  init() {
    const persistedStore = Array.from(PersistStoreMap.values()).find((store) =>
      store.storageName.includes('AppStore')
    )
    if (persistedStore) {
      persistedStore.stopPersisting()
    }
    this.persistStore()
  }

  hasLaunched() {
    this.firstLaunch = false
  }

  async setDeviceType(type) {
    Device.getDeviceTypeAsync()
      .then((response) => (this.deviceType = response))
      .catch((error) => console.log(error))
  }

  get isLargeScreen() {
    if (
      this.deviceType === Device.DeviceType.TABLET ||
      this.deviceType === Device.DeviceType.DESKTOP
    ) {
      return true
    } else return false
  }

  setInitialRoute(index) {
    this.initialRoute = index
  }

  get initialRouteName() {
    return routeMap[this.initialRoute].name
  }

  setInstitution(institution) {
    this.institution = institution
  }

  get institutionName() {
    if (!this.institution) return null
    return this.institutions.find((institution) => institution.value === this.institution).name
  }
}
