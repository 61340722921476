import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootFifthMetatarsal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M46.936 59.963c.785 3.887.55 7.462.518 11.346-.031 3.699-.13 6.003 1.034 9.457 2.648 7.865-1.676 7.115.182 9.228 2.009 2.282 3.156 5.57 4.382 8.033.274.549-.536 1.16 0 1.46.36.202 1.308.057 1.643-.182 1.515-1.08 2.215-3.08 2.625-4.927.41-1.864-.026-3.73-.674-5.484-.755-2.04-1.736-3.988-2.49-6.03-.763-2.064-1.292-4.191-1.645-6.362-.75-4.621-1.2-9.421-1.006-14.103.102-2.45.452-4.872.798-7.3.263-1.858 1.317-3.958.84-5.815-.771-3.01-4.937-4.06-6.39-1.553-1.895 3.276-.488 8.909.183 12.232" />
  </Svg>
);
export default SvgLowerFootFifthMetatarsal;
