import { createContext, useContext } from 'react'
import { configure } from 'mobx'
import { configurePersistable } from 'mobx-persist-store'
import AsyncStorage from '@react-native-async-storage/async-storage'
import AppStore from 'stores/app'
import ContentStore from 'stores/content'

configure({ enforceActions: 'never' })
configurePersistable({ storage: AsyncStorage })

class RootStore {
  constructor() {
    this.appStore = new AppStore(this)
    this.contentStore = new ContentStore(this)
  }
}

const storesContext = createContext({
	rootStore: new RootStore(),
})

const useStores = () => useContext(storesContext)

export {
  useStores,
}