import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgUpperArmHumerusProximal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85 129"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M18.908 16.816c-.484 2.383-1.426 8.948-.707 14.135.092.671.114 1.988.077 3.792-.119 5.834 8.806 6.971 9.954 1.874.4-1.772.771-2.96 1.104-3.407 3.089-4.156 4.523-2.818 6.453-5.521 2.744-3.851 2.515-8.95.66-11.57-1.005-1.42-5.187-4.868-8.721-4.047-3.534.823-4.081 2.668-4.081 2.668-1.435-.386-4.256-.31-4.74 2.076" />
      <Path d="M10 12h34v28H10z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgUpperArmHumerusProximal;
