import { useState, useMemo, useCallback } from 'react'
import { StyleSheet, ScrollView, View, useWindowDimensions } from 'react-native'
import { useTheme, useRoute, useLinkBuilder, useLinkTo } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { observer } from 'mobx-react-lite'
import { useStores } from 'stores'
import { TabView as RNTabView, TabBar } from 'react-native-tab-view'
import { RefreshControl, Text, Icon } from 'components'
import { Theme } from 'styles'


const TabView = observer((props) => {
	const { rootStore } = useStores()
	const { contentStore } = rootStore
	const { colors } = useTheme()

  const { width, height } = useWindowDimensions()
  const { bottom } = useSafeAreaInsets()
  const route = useRoute()
  const buildLink = useLinkBuilder()
  const linkTo = useLinkTo()

  const { routes } = props

  const initialRoute = useMemo(() => {
    let { params } = route
    if (params?.page) {
      return routes.findIndex(route => {
        return route.key === params?.page
      })
    } else return 0
  }, [route.params])

  const handleTabPress = (tabs) => {
    let link = buildLink(route.name, { page: tabs.route.key })
    linkTo(link)
  }

	const renderScene = ({ route }) => {
		return (
			<ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={<RefreshControl />}
      >
        {route.content}
			</ScrollView>
		)
	}

	const renderTabBar = (props) => {
		return (
			<TabBar
				{...props}
				activeColor={colors.text}
				inactiveColor={colors.border}
				scrollEnabled={true}
				getLabelText={({ route }) => route.title}
				tabStyle={styles.tab}
				indicatorStyle={styles.tabIndicator}
				style={styles.tabWrap}
        onTabPress={handleTabPress}
        renderLabel={({ route, color }) => {
          return (
            <Text weight={'bold'} style={[ styles.tabText, { color }]}>{route.title}</Text>
          )
        }}
        renderIcon={({ route, color }) => {
          return (
            route.icon && <Icon name={route.icon} size={'h3'} style={{color}} />
          )
        }}
			/>
		)
	}

  const styles = StyleSheet.create({
  	view: {
  		flex: 1,
  	},
  	scrollView: {
  		padding: Theme.spacing.layout,
  		paddingTop: Theme.spacing.thin,
      paddingBottom: Theme.spacing.layout + bottom,
  	},
    tabWrap: {
      backgroundColor: 'transparent',
    },
    tab: {
			width: 'auto',
      flexDirection: 'row',
      paddingHorizontal: Theme.spacing.layout,
    },
    tabIndicator: {
			height: 4,
			backgroundColor: colors.text,
    },
    tabText: {
      textTransform: 'uppercase',
    },
    tabIcon: {},
  })

  const [ index, setIndex ] = useState(initialRoute)

	return (
		<View style={styles.view}>
			<RNTabView
				navigationState={{ index, routes }}
				renderScene={renderScene}
				renderTabBar={renderTabBar}
				onIndexChange={setIndex}
				initialLayout={{ width: width }}
			/>
		</View>
	)
})


export default TabView