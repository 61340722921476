export const Theme = {

	spacing: {
		layout: 20,
		thin: 10,
		border: 5,
	},

	color: {
		light: {
			dark: false,
			colors: {
				primary: '#ffc3bf',
				background: '#ffffff',
				card: '#f4f4f4',
				overlay: '#ffffff',
				text: '#000000',
				border: '#aaaaaa',
				notification: '#df7169',
				svg: '#222222',
			},
		},
		dark: {
			dark: true,
			colors: {
				primary: '#ffc3bf',
				background: '#000000',
				card: '#222222',
				overlay: '#333333',
				text: '#ffffff',
				border: '#555555',
				notification: '#df7169',
				svg: '#ffffff',
			},
		},
	},

	opacity: {
		translucent: '1a',      // 10%
		light: '40',            // 25%
		medium: '80',           // 50%
		opaque: 'cc',           // 80%
	},

	font: {
		family: {
			thin: 'Poppins-Thin',
			light: 'Poppins-Light',
			regular: 'Poppins-Regular',
			italic: 'Poppins-Italic',
			medium: 'Poppins-Medium',
			semibold: 'Poppins-SemiBold',
			bold: 'Poppins-Bold',
			black: 'Poppins-Black',
		},
		size: {
			h1: 38,
			h2: 24,
			h3: 20,
			h4: 18,
			p: 16,
			sm: 13,
		},
	},

}