import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandTriquetrum = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M15.554 129.95c.999.645 1.986 1.474 3.25 1.337 1.647-.174 1.674-1.014.974-2.578-.245-.542-.258-1.812-.07-2.374.76-2.238 2.226-3.239.859-5.446-.944-1.536-1.286.87-3.726 2.161-2.44 1.292-4.622-.68-4.228.696.73 2.542.558 4.668 2.94 6.203" />
  </Svg>
);
export default SvgHandTriquetrum;
