import { useState, useRef } from 'react'
import { StyleSheet, Modal, View, TextInput } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Constants from 'expo-constants'
import fetch from 'cross-fetch'
import { Formik } from 'formik'
import * as yup from 'yup'
import { observer } from 'mobx-react-lite'
import { Button, Pressable, Text, Icon } from 'components'
import { Theme } from 'styles'

const Feedback = observer((props) => {
  const { environment } = Constants.expoConfig.extra

  const { colors } = useTheme()
  const { bottom } = useSafeAreaInsets()

  const [visible, setVisible] = useState(false)
  const open = () => setVisible(true)
  const close = () => {
    setLoading(false)
    setSuccess(false)
    setError(null)
    setVisible(false)
  }

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)

  const emailRef = useRef(null)
  const messageRef = useRef(null)

  const styles = StyleSheet.create({
    wrap: {
      marginTop: Theme.spacing.layout,
    },
    modal: {
      flex: 1,
      backgroundColor: colors.background,
    },
    modalHeader: {
      padding: Theme.spacing.layout,
      paddingBottom: 0,
      flexDirection: 'row',
    },
    modalTitle: {
      flex: 1,
    },
    modalButton: {
      justifyContent: 'center'
    },
    modalContent: {
      paddingHorizontal: Theme.spacing.layout,
      paddingBottom: bottom,
    },

    fieldset: {
      marginBottom: Theme.spacing.layout,
    },
    label: {},
    input: {
      backgroundColor: colors.card,
      borderRadius: Theme.spacing.border,
      fontSize: Theme.font.size.h4,
      paddingHorizontal: Theme.spacing.thin + Theme.spacing.border,
      paddingVertical: Theme.spacing.thin,
      color: colors.text,
    },
    inputError: {
      backgroundColor: colors.notification + Theme.opacity.light,
    },
    error: {
      fontSize: Theme.font.size.sm,
      color: colors.notification,
    },
    disabled: {
      color: colors.border,
    },
    textArea: {
      textAlignVertical: 'top',
      height: 150,
      paddingTop: Theme.spacing.thin,
    },
    message: {
      marginBottom: Theme.spacing.thin,
    }
  })

  const validationSchema = yup.object().shape({
    message: yup.string().required('A message is required.'),
    email: yup.string().email('An email is optional but must be valid if entered.'),
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true)
      setError(null)
      setSuccess(null)

      const response = await fetch(environment.domain + environment.email, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`)
      }
      const jsonResponse = await response.json()
      setLoading(false)
      setSuccess(true)
    }
    catch (error) {
      setLoading(false)
      setError(error.message)
    }
    finally {
      setSubmitting(false)
    }
  }

  const handleNext = () => {
    messageRef.current.focus()
  }

  const renderActions = (handleSubmit) => {
    if (success) {
      return (
        <View>
          <Text style={styles.message}>Thank you for your feedback!</Text>
          <Button
            text={'Close'}
            icon={'close'}
            onPress={close}
          />
        </View>
      )
    }
    else if (error) {
      return (
        <View>
          <Text style={styles.message}>Sorry, your message could not be sent. Please try again later.!</Text>
          <Button
            text={'Close'}
            icon={'close'}
            onPress={close}
          />
        </View>
      )
    }
    else {
      return (
        <Button
          disabled={loading}
          text={'Send'}
          icon={'email'}
          onPress={handleSubmit}
        />
      )
    }
  }

  return (
    <View style={styles.wrap}>
      <Button text={'Feedback'} icon={'email'} onPress={open} />
      <Modal
        visible={visible}
        presentationStyle={'pageSheet'}
        animationType={'slide'}
        onRequestClose={close}
      >
        <View style={styles.modal}>
          <View style={styles.modalHeader}>
            <Text style={styles.modalTitle} size={'h1'} numberOfLines={1} weight={'bold'}>Feedback</Text>
            <Pressable onPress={close} style={styles.modalButton}>
              <Icon name={'close'} size={'h1'} />
            </Pressable>
          </View>
          <KeyboardAwareScrollView contentContainerStyle={styles.modalContent}>
            <Formik
              initialValues={{ title: props.title, message: '', email: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => {
                return (
                  <>
                    <View style={styles.fieldset}>
                      <Text stlye={styles.label}>Title:</Text>
                      <TextInput
                        label={'Title'}
                        onChangeText={handleChange('title')}
                        onBlur={handleBlur('title')}
                        value={values.title}
                        error={errors.title}
                        style={[styles.input, styles.disabled]}
                        editable={false}
                      />
                    </View>
                    <View style={styles.fieldset}>
                      <Text stlye={styles.label}>Your Email:</Text>
                      <TextInput
                        ref={emailRef}
                        label={'Your Email'}
                        onChangeText={handleChange('email')}
                        onBlur={handleBlur('email')}
                        value={values.email}
                        error={errors.email}
                        autoComplete={'email'}
                        inputMode={'email'}
                        keyboardType={'email-address'}
                        textContentType={'emailAddress'}
                        enterKeyHint={'next'}
                        returnKeyType={'next'}
                        onSubmitEditing={handleNext}
                        style={[styles.input, errors.email && styles.inputError]}
                      />
                      {errors.email && touched.email && <Text style={styles.error}>{errors.email}</Text>}
                    </View>
                    <View style={styles.fieldset}>
                      <Text stlye={styles.label}>Message:</Text>
                      <TextInput
                        ref={messageRef}
                        label={'Message'}
                        onChangeText={handleChange('message')}
                        onBlur={handleBlur('message')}
                        value={values.message}
                        error={errors.message}
                        multiline={true}
                        style={[styles.input, styles.textArea, errors.message && styles.inputError]}
                      />
                      {errors.message && touched.message && <Text style={styles.error}>{errors.message}</Text>}
                    </View>
                    {renderActions(handleSubmit)}
                  </>
                )
              }}
            </Formik>
          </KeyboardAwareScrollView>
        </View>
      </Modal>
    </View>
  )
})

export default Feedback