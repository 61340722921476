import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerLegFibulaProximal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 136"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M25.818 15.165c-1.292 1.453-1.775 3.119-2.909 4.798-.887 1.31-2.578 2.424-3.054 3.782-.815 2.329-.61 5.838-.341 9.264.153 1.964 3.489 1.598 3.566.164.113-2.09.3-4.178.701-6.083.574-2.72 2.188-4.299 3.491-6.542.041-2.324.161-4.72-1.308-5.528-.125-.029-.137.054-.146.145" />
      <Path d="M16 15h11v19H16z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgLowerLegFibulaProximal;
