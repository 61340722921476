import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgHandMiddlePhalanges = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89 133"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M55.98 21.277c-.02-1.364.227-3.57-1.268-4.267-1.408-.654-3.883-.198-5.007.83-1.384 1.271-.39 2.94.298 4.283.847 1.636 1.122 3.265.916 5.092-.242 2.128-3.032 7.188-.45 8.703 1.35.791 3.758.046 5.126-.372 1.676-.518 2.75-1.5 2.226-3.344-.511-1.812-1.857-3.234-2.256-5.104-.367-1.735-.173-3.504.256-5.207M38.445 17.344c-.275-2.03-.781-2.763-2.882-2.493-1.24.16-4.188-.029-4.814 1.322-.684 1.461.488 4.11.775 5.587.369 1.874.942 3.808 1.087 5.7.142 1.888-1.138 3.065-1.476 4.811-.378 1.933 1.05 3.186 2.919 2.845.892-.166 1.729-.563 2.605-.787.886-.228 1.814-.263 2.7-.5 2.348-.626 1.54-2.729.453-4.247-1.383-1.93-2.878-3.126-2.867-5.657.005-2.11.308-4.194 1.299-6.076a.3.3 0 0 1 .058.223M16.535 35.88c-.004 1.98.782 2.896 2.504 2.553 2.019-.405 4.589.216 5.852-.536 1.998-1.188.395-2.973-.69-4.79-1.252-2.098-1.253-4.346-.994-6.71.195-1.782 1.028-4.316.394-6.068-.592-1.663-3.184-1.299-4.586-1.427-1.242-.108-3.303-.826-3.511.933-.217 1.837 1.694 3.861 1.76 5.707.065 1.892.585 3.514.693 5.397.104 1.755-1.422 3.23-1.422 4.94M1.578 45.023c.742.951 1.433 1.763 1.478 3.034.044 1.216-.771 2.31-.52 3.521.248 1.193 1.255 2.163 2.364 1.277l.895-.472c.88.165 2.685.616 3.426-.115.877-.864-.671-2.318-1.118-3.052-.631-1.042-.786-2.204-.917-3.4-.118-1.08.102-2.327-.21-3.376-.365-1.22-1.382-1.348-2.498-1.146-.942.175-2.645.183-3.415.775-1.043.8-.078 2.19.515 2.954" />
    </G>
  </Svg>
);
export default SvgHandMiddlePhalanges;
