import * as React from "react";
import Svg, { Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerFootTalus = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 158"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <Path d="M14.318 123.326c2.627 4.827 5.967 12.83 11.544 14.798 6.569 2.32 12.47-2.696 16.298-7.42l.486-2.921c.707-3.51 1.537-7.421-.281-10.742-1.61-2.94-4.763-4.7-7.49-6.448-4.971-3.19-9.885-6.941-16.007-4.24-7.36 3.25-8.038 10.567-4.55 16.973" />
  </Svg>
);
export default SvgLowerFootTalus;
