import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgUpperArmClavicle = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85 129"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M33.92 2.994c.722.283 1.94 3.602 1.836 4.323-.105.72 3.068-1.62 8.118-1.443 5.049.176 17.166-.177 21.35.689S75.468 8.66 79.363 8.277s5.049-2.863 5.482-4.437c.433-1.573 1.299-4.747-.865-3.592s-5.855 4.225-10.646 3.916c-4.791-.31-12.621-1.67-18.79-2.39-8.125-.95-20.623 1.22-20.623 1.22" />
      <Path d="M28 0h57v16H28z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
    </G>
  </Svg>
);
export default SvgUpperArmClavicle;
