import { loadAsync } from 'expo-font'

const loadFonts = async () => {
  return await loadAsync({
    'Poppins-Thin': require('assets/fonts/Poppins-Thin.ttf'),
    'Poppins-Light': require('assets/fonts/Poppins-Light.ttf'),
    'Poppins-Regular': require('assets/fonts/Poppins-Regular.ttf'),
    'Poppins-Italic': require('assets/fonts/Poppins-Italic.ttf'),
    'Poppins-Medium': require('assets/fonts/Poppins-Medium.ttf'),
    'Poppins-SemiBold': require('assets/fonts/Poppins-SemiBold.ttf'),
    'Poppins-Bold': require('assets/fonts/Poppins-Bold.ttf'),
    'Poppins-Black': require('assets/fonts/Poppins-Black.ttf'),
  })
}

export {
  loadFonts,
}