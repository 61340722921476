import * as React from "react";
import Svg, { G, Path } from "react-native-svg"; 
import { Platform } from "react-native";
const SvgLowerThighFemurDistal = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 204"
    style={props.style}
    fill={props.fill}
    fillOpacity={props.fillOpacity}
    strokeWidth={props.strokeWidth}
    stroke={props.stroke}
    pointerEvents={Platform.OS === "ios" ? "auto" : "box-none"}
    onClick={props.onPress}
    {...props}
  >
    <G>
      <Path d="M11 170h41v34H11z" onPress={props.onPress} onClick={props.onPress} style={{outlineWidth: 0}} opacity={0} />
      <Path d="M32.956 170.958c1.611 4.402 4.084 8.345 7.296 8.166 5.082-.285 10.617 6.759 9.818 12.396-1.785 12.58-8.745 16.268-16.752 8.519-1.066-1.032-5.52 4.015-6.67 3.623-4.051-1.374-9.375-3.771-10.243-8.757-.81-4.637 1.75-9.823 2.497-14.33.475-2.86.701-5.588.763-8.27.04-1.8 12.647-3.107 13.291-1.347" />
    </G>
  </Svg>
);
export default SvgLowerThighFemurDistal;
